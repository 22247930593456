import { PATH_FEE } from "../../services/path/fee";
import {
    apiGetWithToken
  } from "../../services";

async function getFeeList(props) {
    let response = "";
    let loading = props.loading ? props.loading : function () {};
    loading(true);
    try {
      response = await apiGetWithToken(`${PATH_FEE.SUCCESS_FEE}`);
      loading(false);
    } catch (error) {
      loading(false);
      response = error.response;
    }
    return response && response.data;
  }

const feeRepository = {
  getFeeList,
};

export default feeRepository;
