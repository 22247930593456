import  firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyCE63vfJ3CCNzi82hoK-t6fwUfYoEIUK7w",
  authDomain: "asgard-dev-4229f.firebaseapp.com",
  databaseURL: "https://asgard-dev-4229f.firebaseio.com",
  projectId: "asgard-dev-4229f",
  storageBucket: "asgard-dev-4229f.appspot.com",
  messagingSenderId: "71595926483",
  appId: "1:71595926483:web:d2230bf64ee609f5fac526",
  measurementId: "G-5Z5X049DM8",
};

// firebase.initializeApp(firebaseConfig);

export default  firebase.initializeApp(firebaseConfig);
