import styled from "styled-components";

export const Section = styled.div`
  .kw-dashboardLeads-button {
    border: 2px solid #2b486d;
    box-sizing: border-box;
    background: unset !important;
    color: #2b486d;
    border-radius: 3px;
  }
`;
