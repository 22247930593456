import TYPE from "../actions/type";

const initialState = {
  upload: {},
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPE.COMMON_UPLOAD_SUCSESS:
      return {
        ...state,
        upload: action.payload,
      };
    case TYPE.COMMON_UPLOAD_ERROR:
      return {
        ...state,
        upload: action.payload,
      };
    case TYPE.BUTTON_SUBMIT_UPLOAD_FORM_UPLOAD:
      return {
        ...state,
        upload: {},
      };
    default:
      return state;
  }
};
