import dispatchType from "./dispatchType";
import leadsRepository from "../../repository/Leads/leads";
import { openNotificationWithIcon } from "../../library/notifications";

export const actionButtonSubmit = (request) => async (dispatch) => {
  try {
    dispatch(dispatchType.buttonBackSubmit());
  } catch (error) {
    console.log(error);
  }
};

export const actionGetLeads = (request) => async (dispatch) => {
  try {
    const getLeadsResponse = await leadsRepository.getLeads({
      params: request,
    });
    dispatch(dispatchType.changeLeadsGetSucsess(getLeadsResponse));
  } catch (error) {
    dispatch(dispatchType.changeLeadsGetError(error));
  }
};

export const postUploadLeads = (
  request,
  handleChangeIsShowFormSupplierBuyerModal,
  setIsShowLoading,
  openNotificationWithIcon
) => async (dispatch) => {
  setIsShowLoading(true);
  const uploadLeadsPost = await leadsRepository.postLeads({ params: request });
  if (uploadLeadsPost.status === 200) {
    setIsShowLoading(false);
    dispatch(dispatchType.leadsPostSucsess(uploadLeadsPost));
    handleChangeIsShowFormSupplierBuyerModal();
    openNotificationWithIcon("success", uploadLeadsPost.message.en);
  } else {
    setIsShowLoading(false);
    dispatch(dispatchType.leadsPostError(uploadLeadsPost));
    openNotificationWithIcon("error", uploadLeadsPost.message.en);
  }
};

export const actionUploadLeads = (
  request,
  handleChangeIsShowUpload,
  setIsShowLoading,
  setIsShowErrorUploadLeads,
  setIsCsvUpload
) => async (dispatch) => {
  setIsShowLoading(true);
  const uploadLeads = await leadsRepository.uploadLeads({ params: request });
  if (uploadLeads.status === 500) {
    dispatch(dispatchType.uploadLeadsError(uploadLeads));
    setIsShowErrorUploadLeads(true);
    setIsShowLoading(false);
    setIsCsvUpload(false);
  } else {
    dispatch(dispatchType.uploadLeadsSucsess(uploadLeads));
    setIsShowLoading(false);
    setIsCsvUpload(false);
    handleChangeIsShowUpload();
    openNotificationWithIcon("success", "Upload Berhasil");
  }
};

export const getProvinceKoinvoice = (request, setIsShowLoading) => async (
  dispatch
) => {
  // setIsShowLoading(true);
  try {
    const responseDistrict = await leadsRepository.getProvince({
      params: request,
    });
    dispatch(dispatchType.ProvinceSucess(responseDistrict));
    // setIsShowLoading(false);
  } catch (error) {
    dispatch(dispatchType.ProvinceError(error));
    // setIsShowLoading(false);
  }
};

export const getCityKoinvoice = (request, setIsShowLoading) => async (
  dispatch
) => {
  // setIsShowLoading(true);
  try {
    const responseDistrict = await leadsRepository.getCity({ params: request });
    dispatch(dispatchType.citySucess(responseDistrict));
    // setIsShowLoading(false);
  } catch (error) {
    dispatch(dispatchType.cityError(error));
    // setIsShowLoading(false);
  }
};
