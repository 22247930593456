import * as yup from "yup";

export const schema = yup.object().shape({
  vendor_uuid: yup.string().required("Required Vendor Id"),
  lead_name: yup.string().required("Required Company Name"),
  lead_address: yup.string().required("Required Company Address"),
  pic_name: yup.string().required("Required PIC Name"),
  pic_email: yup.string().email("Email Not Match").required("Required Business Email"),
  pic_phone_number: yup.string().required("Required PIC Name"),
  business_relationship: yup.string().required("Required Business Relationship"),
  business_category: yup.string().required("Required Business Category"),
});
