import React, { useState, useEffect, useRef } from "react";
import { Table, Card, Button, Input, DatePicker, Select, Tag } from "antd";
import { Section } from "./style";
import DetailsLead from "../../containers/DatailsLead";
import UploadLeadsDashboard from "../../components/UploadLeadsDashboard";
import FormLeadsDashboard from "../../components/FormLeadsDashboard";
import SuccessFormLeadsDashboard from "../../components/SuccesFormLeadsDashboard";
import { actionGetLeads, actionButtonSubmit } from "../../stores/actions/leads";
import { actionButtonSubmitUpload } from "../../stores/actions/upload";
import { connect } from "react-redux";
import leadsRepository from "../../repository/Leads/leads";
import schema from "./schema";
import moment from "moment";
import { useRootContext } from "../../hoc/RootContext";
import loansReaderRepository from "../../repository/Loans/loans";
import { convertToCategoryName } from "../../library/regex";

const { Search } = Input;

const { Option } = Select;

function DashboardLeads(props) {
  const [isShowDetailsLeads, setIsShowDetailsLeads] = useState(false);
  const [isShowUploadModal, setIsShowUploadModal] = useState(false);
  const [leadId, setLeadId] = useState();
  const [kw_user_id, setKw_User_Id] = useState();
  const [
    isShowFormSupplierBuyerModal,
    setIsShowFormSupplierBuyerModal,
  ] = useState(false);
  const [
    isShowSuccesFormSupplierBuyerModal,
    setIsShowSuccesFormSupplierBuyerModal,
  ] = useState(false);
  const [list, setList] = useState([]);
  const [parameter, setParameter] = useState(schema);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const isInitialRender = useRef(true);
  const [account, setAccount] = useState({});
  const accountChange =
    JSON.parse(window.localStorage.getItem("account-change")) !== null &&
    JSON.parse(window.localStorage.getItem("account-change")).data;
  const {
    vendor: { vendor_name },
  } = accountChange;
  const { isAuthenticated, history } = useRootContext();
  const {
    actionGetLeads,
    listLeads,
    leads,
    actionButtonSubmitUpload,
    actionButtonSubmit,
  } = props;
  const mounted = useRef();

  useEffect(() => {
    getList();
    actionGetLeads();
    getAccount();
    isAuthenticated === false && history.push("/"); // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (leads.uploadLeads || leads.postLeads) {
        getList();
        actionButtonSubmit();
        actionButtonSubmitUpload();
      }
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    isInitialRender.current ? (isInitialRender.current = false) : getList(); // eslint-disable-next-line
  }, [parameter]);

  function actionChangeButtoDetailsLeads(value) {
    setIsShowDetailsLeads(!isShowDetailsLeads);
    setLeadId(value && value.lead_uuid && value.lead_uuid);
    setKw_User_Id(value && value.kw_user_id && value.kw_user_id);
  }

  function handleChangeIsShowUpload(value) {
    actionButtonSubmit();
    actionButtonSubmitUpload();
    setIsShowUploadModal(!isShowUploadModal);
    value !== "cancel" && getList();
  }

  function handleChangeIsShowFormSupplierBuyerModal(value) {
    setIsShowFormSupplierBuyerModal(!isShowFormSupplierBuyerModal);
    value !== "cancel" && getList();
  }

  function handleChangeIsShowSucesFormSupplierBuyerModal() {
    setIsShowSuccesFormSupplierBuyerModal(!isShowSuccesFormSupplierBuyerModal);
  }

  function schemaList(
    {
      lead_uuid,
      business_category,
      business_relationship,
      last_applied_loan,
      lead_address,
      lead_name,
      lead_code,
      lead_status,
      pic_email,
      pic_name,
      vendor_uuid,
      pic_phone_number,
      notes,
      kw_user_id,
    },
    index
  ) {
    return {
      index: index,
      lead_code,
      lead_name,
      business_category,
      business_relationship,
      last_applied_loan,
      lead_address,
      lead_status,
      lead_uuid,
      vendor_uuid,
      pic_email,
      pic_name,
      pic_phone_number,
      notes,
      kw_user_id,
    };
  }

  function convertToSchema(response) {
    const list = response;
    return list && list.map((data, index) => schemaList(data, index));
  }

  async function getList() {
    let response = await leadsRepository.getLeads({
      params: parameter,
      loading: setIsShowLoading,
    });
    if (response?.status === 200) {
      setList(convertToSchema(response.data));
      // const tempReversedAuthDocument = [];
      //   if (response?.data !== null) {
      //     for (let i = response?.data?.length - 1; i >= 0; i--) {
      //       tempReversedAuthDocument.push(response.data[i]);
      //     }
      //     setList(convertToSchema(tempReversedAuthDocument));
      //   }
    } else {
      setList([]);
    }
  }

  async function getAccount() {
    let response = await loansReaderRepository.actionGetAccount({});
    if (response.status === 200) {
      setAccount(response.data);
    } else {
      setAccount({});
    }
  }

  const statusSupplierBuyer = [
    {
      text: "Buyer",
      value: "bd8c76d2-b62e-11ea-948f-00163e016d4c",
    },
    {
      text: "Supplier",
      value: "8694335e-b62e-11ea-948f-00163e016d4c",
    },
    {
      text: "Unknown/-",
      value: "1ed22028-863a-11eb-869f-42010a90203a",
    },
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record) => <span>{list.indexOf(record) + 1}</span>,
    },
    {
      title: (
        <>
          <div>Borrower Name</div>
          <div style={{ marginTop: 10 }}>
            <Search
              style={{ width: "100%" }}
              onChange={handleChangeSearchBorrowerName}
              placeholder={"Search..."}
            />
          </div>
        </>
      ),
      dataIndex: "lead_name",
      key: "lead_name",
      render: (text) => <span>{text && convertToCategoryName(text)}</span>,
    },
    {
      title: (
        <>
          <div>Borrower ID</div>
          <div style={{ marginTop: 10 }}>
            {" "}
            <Search
              style={{ width: "100%" }}
              onChange={handleChangeLeadCode}
              placeholder={"Search..."}
            />
          </div>
        </>
      ),
      dataIndex: "lead_code",
      key: "lead_code",
    },
    {
      title: (
        <>
          <div>Supplier/Buyer</div>
          <div style={{ marginTop: 10 }}>
            <Select
              placeholder="All"
              style={{ width: "100%" }}
              onChange={handleChangeBuyerSupplier}
            >
              <Option value={null}>Unknown/-</Option>
              <Option value="buyer">Buyer</Option>
              <Option value="supplier">Supplier</Option>
            </Select>
          </div>
        </>
      ),
      dataIndex: "business_relationship",
      key: "business_relationship",
      render: (text) => (
        <span>
          {text &&
            statusSupplierBuyer.find((status) => status.value === text).text}
        </span>
      ),
    },
    {
      title: (
        <>
          <div>Business Category</div>
          <div style={{ marginTop: 10 }}>
            <Search
              style={{ width: "100%" }}
              onChange={handleChangeSearchBusinessCategory}
              placeholder={"Search..."}
            />
          </div>
        </>
      ),
      dataIndex: "business_category",
      key: "business_category",
      render: (text) => <span>{text && convertToCategoryName(text)}</span>,
    },
    {
      title: (
        <>
          <div>Contact Person</div>
          <div style={{ marginTop: 10 }}>
            <Select
              placeholder="All"
              style={{ width: "100%" }}
              onChange={handleChangePicName}
            >
              {listLeads.data &&
                listLeads.data.map((list, index) => {
                  return (
                    <Option key={index} value={list.pic_name}>
                      {list.pic_name}
                    </Option>
                  );
                })}
            </Select>
          </div>
        </>
      ),
      dataIndex: "pic_name",
      key: "pic_name",
      render: (text) => <span>{text && convertToCategoryName(text)}</span>,
    },
    {
      title: (
        <>
          <div>Leads Page</div>
          <div style={{ marginTop: 10 }}>
            <Search
              style={{ width: "100%" }}
              onChange={handleChangeLeadStatus}
              placeholder={"Search..."}
            />
          </div>
        </>
      ),
      key: "lead_status",
      dataIndex: "lead_status",
      render: (tags) => <>{leadStatusUUidConvert(tags)}</>,
    },
    {
      title: (
        <>
          <div>Last Applied Loan</div>
          <div style={{ marginTop: 10 }}>
            <DatePicker onChange={handleChangeLeadLastApplyLoan} />
          </div>
        </>
      ),
      dataIndex: "last_applied_loan",
      key: "last_applied_loan",
      render: (text) => (
        <span>{text && moment(text).format(" D MMMM YYYY")}</span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text) => (
        <Button
          type="primary"
          onClick={() => actionChangeButtoDetailsLeads(text)}
        >
          Detail
        </Button>
      ),
    },
    {
      title: "Notes",
      key: "notes",
      dataIndex: "notes",
    },
  ];

  function leadStatusUUidConvert(value) {
    let uuidConvert = "-";
    if (value === "fd1e783c-f26e-11ea-9bec-b8599f27b26c") {
      uuidConvert = <Tag color="#0E8C11">Applied</Tag>;
    } else if (value === "bd8c76d2-b62e-11ea-948f-00163e016d4f") {
      uuidConvert = <Tag color="#F7941D">Pending</Tag>;
    } else if (value === "6c328c30-70f5-40a0-a3f6-9fae3ff5d275") {
      uuidConvert = <Tag>Invited</Tag>;
    }
    return uuidConvert;
  }

  function handleChangeBuyerSupplier(value) {
    setParameter({
      ...parameter,
      business_relationship: value,
    });
  }

  function handleChangeSearchBorrowerName(value) {
    setParameter({
      ...parameter,
      lead_name: value.target.value,
    });
  }

  function handleChangeSearchBusinessCategory(value) {
    setParameter({
      ...parameter,
      business_category: value.target.value,
    });
  }

  function handleChangePicName(value) {
    setParameter({
      ...parameter,
      pic_name: value,
    });
  }

  function handleChangeLeadCode(value) {
    setParameter({
      ...parameter,
      lead_code: value.target.value,
    });
  }

  function handleChangeLeadStatus(value) {
    setParameter({
      ...parameter,
      lead_status: value.target.value,
    });
  }

  function handleChangeLeadLastApplyLoan(value, option) {
    setParameter({
      ...parameter,
      last_applied_loan: option,
    });
  }

  return (
    <Section>
      <div
        className="container-fluid"
        style={{ marginTop: 50, marginLeft: 10, marginBottom: 50 }}
      >
        {isShowDetailsLeads === true && (
          <h3 className="kw-dashboardLeads-heading">
            Principal :
            {vendor_name.charAt(0).toUpperCase() + vendor_name.slice(1)}
          </h3>
        )}
        <Card>
          {isShowDetailsLeads === false ? (
            <React.Fragment>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3 className="kw-dashboardLeads-heading">
                  Principal :{" "}
                  {vendor_name.charAt(0).toUpperCase() + vendor_name.slice(1)}
                </h3>
                <div>
                  <Button
                    type="primary"
                    className="kw-dashboardLeads-button"
                    style={{ marginRight: 10 }}
                    onClick={() =>
                      handleChangeIsShowFormSupplierBuyerModal("cancel")
                    }
                  >
                    + Supplier / buyer
                  </Button>
                  <Button
                    type="primary"
                    className="kw-dashboardLeads-button"
                    onClick={() => handleChangeIsShowUpload("cancel")}
                  >
                    + Bulk upload
                  </Button>
                </div>
              </div>
              <Table
                rowKey={(record) => record.index}
                scroll={{ x: 1777 }}
                columns={columns}
                loading={isShowLoading}
                dataSource={list}
              />
            </React.Fragment>
          ) : (
            <DetailsLead
              actionChangeButtoDetailsLeads={actionChangeButtoDetailsLeads}
              leadId={leadId}
              kw_user_id={kw_user_id}
            />
          )}
        </Card>
      </div>
      <UploadLeadsDashboard
        handleChangeIsShowUpload={handleChangeIsShowUpload}
        isShowUploadModal={isShowUploadModal}
      />
      <FormLeadsDashboard
        account={account}
        handleChangeIsShowFormSupplierBuyerModal={
          handleChangeIsShowFormSupplierBuyerModal
        }
        isShowFormSupplierBuyerModal={isShowFormSupplierBuyerModal}
      />
      <SuccessFormLeadsDashboard
        handleChangeIsShowSucesFormSupplierBuyerModal={
          handleChangeIsShowSucesFormSupplierBuyerModal
        }
        isShowSuccesFormSupplierBuyerModal={isShowSuccesFormSupplierBuyerModal}
      />
    </Section>
  );
}

const mapStateToProps = (state) => ({
  listLeads: state.leads.listLeads,
  leads: state.leads,
});

export default connect(mapStateToProps, {
  actionGetLeads,
  actionButtonSubmit,
  actionButtonSubmitUpload,
})(DashboardLeads);
