import React from 'react';
import Container from '../../containers/Error';

const ErrorPage = () => (
  <React.Fragment>
    <div className="page-content">
      <Container error={404} />
    </div>
  </React.Fragment>
);

export default ErrorPage;
