import styled from "styled-components";

export const Section = styled.div`
  .kw-docs-button-language {
    font-weight: bold;
    text-align: center;
    line-height: 16px;
    margin-bottom: 0;
    padding: 10px 17px;
    cursor: pointer;
  }
  .active {
    color: white;
    background: #2b486d;
  }
`;
