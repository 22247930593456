import {
  apiGetWithToken,
  apiPostWithToken,
  apiPutWithToken,
} from "../../services";
import { PATH_DOCUMENTS } from "../../services/path/documents";
import { PATH_PRINCIPAL } from "../../services/path/leads";

async function getLeads(props) {
  let response = "";
  let params = props.params;
  let loading = props.loading ? props.loading : function () {};
  loading(true);
  try {
    response = await apiGetWithToken(PATH_PRINCIPAL.PRINCIPAL_LEADS, params);
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
  }
  return response && response.data;
}

async function getDownloadCsvTemplateLeads(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () {};
  loading(true);
  try {
    response = await apiGetWithToken(PATH_PRINCIPAL.LEADS_UPLOAD_TEMPLATE);
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
  }
  return response && response.data;
}

async function getLeadsDetails(props) {
  let response = "";
  let paramsId = props.paramsId;
  try {
    response = await apiGetWithToken(
      `${PATH_PRINCIPAL.PRINCIPAL_LEADS}/${paramsId}`
    );
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function getPrincipalAccount(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () {};
  loading(true);
  try {
    response = await apiGetWithToken(PATH_PRINCIPAL.PRINCIPAL_ACCOUNT);
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
  }
  return response && response.data;
}

async function getProvince(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () {};
  let params = props.params;
  loading(true);
  try {
    response = await apiGetWithToken(`${PATH_PRINCIPAL.PROVINCE}${params}`);
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
  }
  return response && response.data;
}

async function getCity(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () {};
  let params = props.params;
  loading(true);
  try {
    response = await apiGetWithToken(`${PATH_PRINCIPAL.CITY}${params}`);
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
  }
  return response && response.data;
}

async function postLeads(props) {
  let response = "";
  let params = props.params;
  try {
    response = await apiPostWithToken(PATH_PRINCIPAL.PRINCIPAL_LEADS, params);
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function actionPostUpdateGeneral(props) {
  let response = "";
  let params = props.params;
  let paramsUUID = props.paramsUUID;
  try {
    response = await apiPostWithToken(
      `${PATH_PRINCIPAL.KOINREVOLVER_UPDATE}${paramsUUID}`,
      params
    );
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function actionPostUpdateDetails(props) {
  let response = "";
  let params = props.params;
  let paramsUUID = props.paramsUUID;
  try {
    response = await apiPostWithToken(
      `${PATH_PRINCIPAL.KOINREVOLVER_DETAILS}${paramsUUID}`,
      params
    );
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function uploadLeads(props) {
  let response = "";
  let params = props.params;
  try {
    response = await apiPostWithToken(
      PATH_PRINCIPAL.KOINREVOLVER_PRINCIPAL_LEADS_UPLOAD,
      params
    );
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function getLeadsDocuments(props) {
  let response = "";
  let paramsId = props.paramsId;
  let loading = props.loading ? props.loading : function () {};
  loading(true);
  try {
    response = await apiGetWithToken(
      `${PATH_DOCUMENTS.DOCUMENTS}?user_id=${paramsId}`
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
  }
  return response && response.data;
}

async function changeDocumentApproveRejected(props) {
  let response = "";
  let paramsId = props.paramsId;
  let params = props.params;
  let loading = props.loading ? props.loading : function () {};
  loading(true);
  try {
    response = await apiPutWithToken(
      `${PATH_DOCUMENTS.DOCUMENTS}/submission/${paramsId}`,
      params
    );
    loading(false);
  } catch (error) {
    response = error.response;
    loading(false);
  }
  return response && response.data;
}

const leadsRepository = {
  changeDocumentApproveRejected,
  getLeadsDocuments,
  actionPostUpdateDetails,
  actionPostUpdateGeneral,
  getLeads,
  getLeadsDetails,
  postLeads,
  uploadLeads,
  getDownloadCsvTemplateLeads,
  getPrincipalAccount,
  getProvince,
  getCity,
};

export default leadsRepository;
