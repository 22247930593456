import React from "react";
import { Formik } from "formik";
import { Form, Row, Col, Input, Select, Button, notification } from "antd";
import * as yup from "yup";
import {
  getProvinceKoinvoice,
  getCityKoinvoice,
} from "../../stores/actions/leads";
import { connect } from "react-redux";
import { useState } from "react";
import leadsRepository from "../../repository/Leads/leads";
import NumberFormat from "react-number-format";
import { useRootContext } from "../../hoc/RootContext";

const { TextArea } = Input;
const { Option } = Select;

const openNotificationWithIcon = (type, message) => {
  notification[type]({
    message: message,
    duartion: 100,
  });
};

function General({
  getProvinceKoinvoice,
  getCityKoinvoice,
  principalAccount,
  leads: { province, city },
}) {
  const [disabledForm, setDisabledForm] = useState(true);
  const [disabledButtonEdit, setDisabledButtonEdit] = useState(false);
  const { actionPrincipalAccount } = useRootContext();
  const schemaGeneral = yup.object().shape({
    vendor_name: yup.string().required("Required Vendor Name"),
    product_type: yup.string(),
    address: yup.string().required("Required Address"),
    province: yup.string().required("Required Province"),
    city: yup.string().required("Required City"),
    phone_number: yup.string().required("Required City"),
  });

  function actionChangeSearchProvince(value) {
    getProvinceKoinvoice(value);
  }

  function actionChangeProvince(value, option, setFieldValue) {
    setFieldValue("province", value);
  }

  function actionChangeSearchCity(value) {
    getCityKoinvoice(value);
  }

  function actionChangeCity(value, option, setFieldValue) {
    setFieldValue("city", value);
  }

  function handleChangeAddress(value, option, setFieldValue) {
    setFieldValue("address", value.target.value);
  }

  function actionChangeContactNumber(value, option, setFieldValue) {
    let phone_number = value.target.value.replace(/[-_\s]/g, "");
    setFieldValue("phone_number", phone_number.toString());
  }

  function actionChangeEnabledDisabled() {
    setDisabledForm(false);
    setDisabledButtonEdit(true);
  }

  async function actionSubmitGeneral(params) {
    let response = await leadsRepository.actionPostUpdateGeneral({
      params: params,
      paramsUUID: principalAccount && principalAccount.vendor.vendor_uuid,
    });
    console.log("actionSubmitGeneral", response);
    if (response.status === 200) {
      setDisabledForm(true);
      openNotificationWithIcon(
        "success",
        `${response.message.en} / ${response.message.id}`
      );
      actionPrincipalAccount();
      setDisabledButtonEdit(false);
    } else {
      setDisabledButtonEdit(false);
      openNotificationWithIcon(
        "error",
        `${response.message.en} / ${response.message.id}`
      );
    }
  }

  function handleChangeSubmit(params) {
    Object.assign(params, {
      properties: {
        test1: principalAccount && principalAccount.vendor.properties.test1,
        test2: principalAccount && principalAccount.vendor.properties.test2,
      },
    });
    actionSubmitGeneral(params);
  }

  function actionChangeCancel() {
    window.location.reload();
  }

  const optionsProvince =
    province.data &&
    province.data.map((dis) => <Option key={dis.name}>{dis.name}</Option>);

  const optionsCity =
    city.data &&
    city.data.map((dis) => <Option key={dis.name}>{dis.name}</Option>);

  return (
    <Formik
      initialValues={{
        product_type: "a1fb40e7-e9c5-11e9-97fa-00163e010bca",
        vendor_name: principalAccount && principalAccount.vendor.vendor_name,
        address: principalAccount && principalAccount.vendor.address,
        province: principalAccount && principalAccount.vendor.province,
        city: principalAccount && principalAccount.vendor.city,
        phone_number: principalAccount && principalAccount.vendor.phone_number,
      }}
      enableReinitialize
      validationSchema={schemaGeneral}
      validateOnChange={false}
      onSubmit={(values) => {
        handleChangeSubmit(values);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={24} md={12}>
              <Form.Item
                label="VENDOR CODE"
                validateStatus={errors.vendor_code && "error"}
                help={errors.vendor_code}
              >
                <Input
                  size="large"
                  disabled={true}
                  name="vendor_code"
                  //   onChange={handleChange}
                  value={
                    principalAccount && principalAccount.vendor.vendor_code
                  }
                />
              </Form.Item>
              <Form.Item
                label={"VENDOR NAME"}
                validateStatus={errors.vendor_name && "error"}
                help={errors.vendor_name}
              >
                {" "}
                <Input
                  size="large"
                  name="vendor_name"
                  disabled={true}
                  onChange={handleChange}
                  value={values.vendor_name}
                />
              </Form.Item>
              <Form.Item
                label={"ADDRESS"}
                validateStatus={errors.address && "error"}
                help={errors.address}
              >
                <TextArea
                  placeholder="Atas Nama"
                  disabled={disabledForm}
                  name="address"
                  autoSize={{ minRows: 1.5, maxRows: 7 }}
                  onChange={(value, option) =>
                    handleChangeAddress(value, option, setFieldValue)
                  }
                  value={values.address}
                />
              </Form.Item>
              <Form.Item
                label={"CITY"}
                validateStatus={errors.city && "error"}
                help={errors.city}
              >
                <Select
                  showSearch
                  value={values.city}
                  name={"city"}
                  disabled={disabledForm}
                  size="large"
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={actionChangeSearchCity}
                  onChange={(value, option) =>
                    actionChangeCity(value, option, setFieldValue)
                  }
                >
                  {optionsCity}
                </Select>
              </Form.Item>
              <Form.Item
                label={"PROVINCE"}
                validateStatus={errors.province && "error"}
                help={errors.province}
              >
                <Select
                  showSearch
                  value={values.province}
                  name={"province"}
                  disabled={disabledForm}
                  size="large"
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={actionChangeSearchProvince}
                  onChange={(value, option) =>
                    actionChangeProvince(value, option, setFieldValue)
                  }
                >
                  {optionsProvince}
                </Select>
              </Form.Item>
              <Form.Item
                label={"PHONE NUMBER (WITH EXTENSION IF AVAILABLE)"}
                validateStatus={errors.phone_number && "error"}
                help={errors.phone_number}
                onBlur={handleBlur}
              >
                <NumberFormat
                  style={{
                    height: 40,
                    border: "1px solid #d9d9d9",
                    width: "100%",
                    padding: 10,
                    color: disabledForm ? "rgba(0,0,0,.25)" : "black",
                  }}
                  disabled={disabledForm}
                  name="phone_number"
                  type="tel"
                  format="####-###-###-###"
                  onChange={(value, option) =>
                    actionChangeContactNumber(value, option, setFieldValue)
                  }
                  value={values.phone_number}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <div style={{ position: "absolute", top: 0, right: 0 }}>
                {" "}
                <Button
                  type="primary"
                  disabled={disabledButtonEdit}
                  onClick={() => actionChangeEnabledDisabled()}
                  size={"large"}
                >
                  EDIT COMPANY
                </Button>
              </div>
              <div style={{ position: "absolute", bottom: 50, right: 0 }}>
                <Button
                  size="large"
                  style={{ marginRight: 20 }}
                  disabled={disabledForm}
                  onClick={() => actionChangeCancel()}
                >
                  {" "}
                  CLOSE
                </Button>
                <Button
                  type="primary"
                  disabled={disabledForm}
                  htmlType="submit"
                  size={"large"}
                >
                  SAVE
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
}

const mapStateToProps = (state) => ({
  leads: state.leads,
});

export default connect(mapStateToProps, {
  getProvinceKoinvoice,
  getCityKoinvoice,
})(General);
