import React, { useEffect, useState } from "react";
import { Button, Table, Spin, notification, Row, Col } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { convertToCategoryName } from "../../library/regex";
import leadsRepository from "../../repository/Leads/leads";
import moment from "moment";
import loansReaderRepository from "../../repository/Loans/loans";
import "./style.css";
import currencyRupiah from "../../library/currency";

const openNotificationWithIcon = (type, label, message) => {
  notification[type]({
    message: label,
    description: message,
  });
};

const DetailsLead = ({ actionChangeButtoDetailsLeads, leadId, kw_user_id }) => {
  const [dataLeadsDetails, setDataLeadsDetails] = useState({});
  const [uclData, setUclData] = useState({});
  const [documentDetails, setDocumentDetails] = useState([]);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isShowLoadingApproved, setIsShowLoadingApproved] = useState(false);
  const [isShowLoadingRejected, setIsShowLoadingRejected] = useState(false);

  function schemaList(
    { document_type, properties, user_document_id, url_location, user_id },
    index,
    data
  ) {
    return {
      index,
      document_type,
      user_document_id,
      user_id,
      url_location,
      data,
      uploaded_on:
        properties.koin_invoice &&
        properties.koin_invoice.loans &&
        properties.koin_invoice.loans[0] &&
        properties.koin_invoice.loans[0].upload_at,
      docs_origin:
        properties.koin_invoice &&
        properties.koin_invoice.loans &&
        properties.koin_invoice.loans[0] &&
        properties.koin_invoice.loans[0].docs_origin,
      status:
        properties.koin_invoice &&
        properties.koin_invoice.loans &&
        properties.koin_invoice.loans[0] &&
        properties.koin_invoice.loans[0].status,
      notes:
        properties.koin_invoice &&
        properties.koin_invoice.loans &&
        properties.koin_invoice.loans[0] &&
        properties.koin_invoice.loans[0].notes,
    };
  }

  function convertToSchema(response) {
    return (
      response &&
      response.data.map((data, index) => schemaList(data, index, data))
    );
  }

  function changeDetailsLeads() {
    leadsRepository
      .getLeadsDetails({ paramsId: leadId, loading: setIsShowLoading })
      .then((data) => {
        if (data.status === 200) {
          setDataLeadsDetails(data);
        }
      });
    leadsRepository
      .getLeadsDocuments({ paramsId: kw_user_id, loading: setIsShowLoading })
      .then((res) => {
        if (res.status === 200) {
          setDocumentDetails(convertToSchema(res));
        }
      });
    loansReaderRepository.geUclList({ paramsId: kw_user_id }).then((res) => {
      console.log("geUclList", res);
      if (res.status === 200) {
        setUclData(res.data);
      }
    });
  }

  useEffect(() => {
    changeDetailsLeads(); // eslint-disable-next-line
  }, []);

  function handleChangeApproved(value) {
    let params = {
      document_url: value.url_location,
      properties: {
        koin_bisnis: {
          loans: [
            {
              loan_id: value.user_id.toString(),
              notes: "verified",
              status: "approved",
              tag: "Uploaded, Verified",
            },
          ],
        },
      },
    };
    leadsRepository
      .changeDocumentApproveRejected({
        params: params,
        paramsId: value.user_document_id,
        loading: setIsShowLoadingApproved,
      })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          changeDetailsLeads();
        } else {
          openNotificationWithIcon(
            "error",
            res.message.en,
            res.errors.userMessage
          );
        }
      });
  }

  function handleChangeReject(value) {
    let params = {
      document_url: value.url_location,
      properties: {
        koin_bisnis: {
          loans: [
            {
              loan_id: value.user_id.toString(),
              notes: "Rejected",
              status: "rejected",
              tag: "Uploaded, Rejected",
            },
          ],
        },
      },
    };
    leadsRepository
      .changeDocumentApproveRejected({
        params: params,
        paramsId: value.user_document_id,
        loading: setIsShowLoadingRejected,
      })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          changeDetailsLeads();
        } else {
          openNotificationWithIcon(
            "error",
            res.message.en,
            res.errors.userMessage
          );
        }
      });
  }

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, value, index) => <span>{index + 1}</span>,
    },
    {
      title: "Document Type",
      dataIndex: "document_type",
      key: "document_type",
      render: (text) => (
        <span style={{ color: "#43ADF7" }}>
          {text && convertToCategoryName(text)}
        </span>
      ),
    },
    {
      title: "Uploaded On",
      dataIndex: "invoice_date",
      key: "invoice_date",
      render: (text) => <span>{moment(text).format(" D MMMM YYYY")}</span>,
    },
    {
      title: "Docs origin",
      dataIndex: "docs_origin",
      key: "docs_origin",
    },
    {
      title: "Docs Validation",
      dataIndex: "data",
      key: "data",
      render: (text) => {
        let statusCondition =
          text.properties.koin_invoice &&
          text.properties.koin_invoice.loans &&
          text.properties.koin_invoice.loans[0] &&
          text.properties.koin_invoice.loans[0].status;
        return (
          <div style={{ display: "flex", justifyContent: "space-arround" }}>
            <Button
              onClick={() => handleChangeApproved(text)}
              size="large"
              loading={isShowLoadingApproved}
              disabled={statusCondition === "approved" ? true : false}
              style={{
                marginRight: 15,
                background:
                  statusCondition === "approved" ? "#1890ff" : undefined,
                color: statusCondition === "approved" ? "#FFFFFF" : undefined,
              }}
            >
              {statusCondition === "approved" ? "Confirmed" : "Confirm"}
            </Button>
            <Button
              onClick={() => handleChangeReject(text)}
              loading={isShowLoadingRejected}
              className="kw-details-reject"
              disabled={statusCondition === "rejected" ? true : false}
              size="large"
              danger={statusCondition === "rejected" ? true : false}
            >
              {statusCondition === "rejected" ? "Rejected" : "Reject"}
            </Button>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        let textColor = "#566068";
        if (text === "approved") {
          textColor = "#41AF60";
        } else if (text === "rejected") {
          textColor = "#C53741";
        }
        return (
          <span style={{ color: textColor }}>
            {text && convertToCategoryName(text)}
          </span>
        );
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (text) => (
        <span style={{ color: "#566068" }}>
          {text && convertToCategoryName(text)}
        </span>
      ),
    },
  ];

  let textColor = "#566068";
  if (uclData && uclData.status === "approved") {
    textColor = "#41AF60";
  } else if (uclData && uclData.status === "rejected") {
    textColor = "#C53741";
  }

  return (
    <React.Fragment>
      <Button
        onClick={() => actionChangeButtoDetailsLeads()}
        style={{ float: "right", color: "black" }}
      >
        <ArrowLeftOutlined />
        Back
      </Button>
      <h5 style={{ color: "#566068", fontWeight: "bold" }}>Borrower Details</h5>
      <Spin spinning={isShowLoading}>
        <Row>
          <Col md={12}>
            <table>
              <tbody>
                <tr>
                  <td style={{ fontWeight: 500 }}>Borrower Name </td>
                  <td>
                    :{" "}
                    {dataLeadsDetails.data &&
                      dataLeadsDetails.data.borrower_detail.lead_name}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 500 }}>Established in</td>
                  <td>
                    :{" "}
                    {dataLeadsDetails.data &&
                      dataLeadsDetails.data.borrower_detail.last_applied_loan}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 500 }}>Business Address</td>
                  <td>
                    :{" "}
                    {dataLeadsDetails.data &&
                      dataLeadsDetails.data.borrower_detail.lead_address}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 500 }}>Business Email</td>
                  <td>
                    :{" "}
                    {dataLeadsDetails.data &&
                      dataLeadsDetails.data.borrower_detail.pic_email}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 500 }}>Business Category</td>
                  <td>
                    :{" "}
                    {dataLeadsDetails.data &&
                      dataLeadsDetails.data.borrower_detail.business_category}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 500 }}>Contact Person</td>
                  <td>
                    :{" "}
                    {dataLeadsDetails.data &&
                      dataLeadsDetails.data.borrower_detail.pic_name}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 500 }}>Buyer/Supplier?</td>
                  <td>
                    :{" "}
                    {dataLeadsDetails.data &&
                      convertToCategoryName(
                        dataLeadsDetails.data.borrower_detail
                          .business_relationship
                      )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>{" "}
          <Col md={12}>
            <table>
              <tbody>
                <tr>
                  <td style={{ fontWeight: 500 }}>Status</td>
                  <td>
                    :{" "}
                    {uclData && uclData.status && (
                      <span style={{ color: textColor }}>
                        {" "}
                        {convertToCategoryName(uclData.status)}
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 500 }}>Amount</td>
                  <td>
                    :{" "}
                    {uclData.initial_limit > 0 &&
                      currencyRupiah(uclData.initial_limit)}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 500 }}>Duration</td>
                  <td>
                    : {uclData.max_tenur > 0 && `${uclData.max_tenur} months`}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 500 }}>Disbursement Reqs</td>
                  <td>
                    :
                    {uclData &&
                      uclData.properties &&
                      uclData.properties.disbursement_add_on_req &&
                      uclData.properties.disbursement_add_on_req.map((e) => {
                        return e;
                      })}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </Spin>
      <h5 style={{ color: "#566068", fontWeight: "bold", marginTop: 22 }}>
        Document Details
      </h5>
      <Table
        rowKey={(record) => record.index}
        dataSource={dataLeadsDetails.data && documentDetails}
        columns={columns}
        loading={isShowLoading}
      />
    </React.Fragment>
  );
};

export default DetailsLead;
