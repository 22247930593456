import React from "react";
import config from '../config';

export default function LinkForgotPassword(props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 10
      }}
    >
      <a
        href={config.api.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {" "}
        Lupa Password ?
      </a>
    </div>
  );
}


