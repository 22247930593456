import TYPE from "./type";


const buttonBackSubmit = authData => {
  return {
    type: TYPE.BUTTON_SUBMIT_UPLOAD_FORM,
    payload: authData
  }
}

const buttonBackSubmitUpload = authData => {
  return {
    type: TYPE.BUTTON_SUBMIT_UPLOAD_FORM_UPLOAD,
    payload: authData
  }
}

const ProvinceSucess = (authData) => {
  return {
    type: TYPE.PROVINCE_SUCSESS,
    payload: authData,
  };
};

const ProvinceError = (authData) => {
  return {
    type: TYPE.PROVINCE_ERROR,
    payload: authData,
  };
};

const citySucess = (authData) => {
  return {
    type: TYPE.CITY_SUCSESS,
    payload: authData,
  };
};

const cityError = (authData) => {
  return {
    type: TYPE.CITY_ERROR,
    payload: authData,
  };
};

const changeLeadsGetSucsess = (authData) => {
  return {
    type: TYPE.GET_LEADS_SUCSESS,
    payload: authData,
  };
};

const changeLeadsGetError = (authData) => {
  return {
    type: TYPE.GET_LEADS_SUCSESS_ERROR,
    payload: authData,
  };
};

const leadsPostSucsess = (authData) => {
  return {
    type: TYPE.POST_LEADS_SUCSESS,
    payload: authData,
  };
};

const leadsPostError = (authData) => {
  return {
    type: TYPE.POST_LEADS_ERROR,
    payload: authData,
  };
};

const uploadLeadsSucsess = (authData) => {
  return {
    type: TYPE.POST_UPLOAD_LEADS_SUCSESS,
    payload: authData,
  };
};

const uploadLeadsError = (authData) => {
  return {
    type: TYPE.POST_UPLOAD_LEADS_ERROR,
    payload: authData,
  };
};

const commonUploadSucsess = (authData) => {
  return {
    type: TYPE.COMMON_UPLOAD_SUCSESS,
    payload: authData,
  };
};

const commonUploadError = (authData) => {
  return {
    type: TYPE.COMMON_UPLOAD_ERROR,
    payload: authData,
  };
};

const dispatchType = {
  buttonBackSubmitUpload,
  buttonBackSubmit,
  changeLeadsGetSucsess,
  changeLeadsGetError,
  leadsPostSucsess,
  leadsPostError,
  uploadLeadsSucsess,
  uploadLeadsError,
  commonUploadSucsess,
  commonUploadError,
  ProvinceSucess,
  ProvinceError,
  citySucess,
  cityError
};

export default dispatchType;
