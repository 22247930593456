import "./polyfills.js";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./stores";
import { GlobalStyle } from "./globalStyle";
// import  firebase from 'firebase/app';

// const firebaseConfig = {
//   apiKey: "AIzaSyCE63vfJ3CCNzi82hoK-t6fwUfYoEIUK7w",
//   authDomain: "asgard-dev-4229f.firebaseapp.com",
//   databaseURL: "https://asgard-dev-4229f.firebaseio.com",
//   projectId: "asgard-dev-4229f",
//   storageBucket: "asgard-dev-4229f.appspot.com",
//   messagingSenderId: "71595926483",
//   appId: "1:71595926483:web:d2230bf64ee609f5fac526",
//   measurementId: "G-5Z5X049DM8",
// };

// firebase.initializeApp(firebaseConfig);

const Root = ({ store, children }) => (
  <Provider store={store}>{children}</Provider>
);

ReactDOM.render(
  <Root store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <GlobalStyle />
      <App />
    </PersistGate>
  </Root>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
