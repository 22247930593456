import React, { useState } from "react";
import { Formik } from "formik";
import { Form, Row, Col, Input, Button, notification, DatePicker } from "antd";
import moment from "moment";
import NumberFormat from "react-number-format";
import * as yup from "yup";
import leadsRepository from "../../repository/Leads/leads";
import { useRootContext } from "../../hoc/RootContext";

const openNotificationWithIcon = (type, message) => {
  notification[type]({
    message: message,
    duartion: 100,
  });
};

function Details({ principalAccount }) {
  const [disabledForm, setDisabledForm] = useState(true);
  const [disabledButtonEdit, setDisabledButtonEdit] = useState(false);
  const { actionPrincipalAccount } = useRootContext();
  const schemaDetails = yup.object().shape({
    payor_name: yup.string().required("Required"),
    email: yup.string().required("Required"),
    phone_number: yup.number().required("Required"),
    fee_absorption: yup.string().required("Required"),
    freeze_status: yup.string().required("Required"),
    established_on: yup.string().required("Required"),
    bank_name: yup.string().required("Required"),
    bank_branch: yup.string().required("Required"),
    account_name: yup.string().required("Required"),
    account_number: yup.string().required("Required"),
  });

  function actionChangeEnabledDisabled() {
    setDisabledForm(false);
    setDisabledButtonEdit(true);
  }

  function actionChangeFeeAbsorption(value, option, setFieldValue) {
    let numberSaham = value.target.value.replace(/%/g, " ");
    if (numberSaham > 100) {
      numberSaham = 100;
    }
    setFieldValue("fee_absorption", numberSaham.toString());
  }

  function actionChangeEstablishedOn(value, option, setFieldValue) {
    setFieldValue("established_on", value);
  }

  async function actionSubmitDetails(params) {
    let response = await leadsRepository.actionPostUpdateDetails({
      params: params,
      paramsUUID: principalAccount && principalAccount.vendor.vendor_uuid,
    });
    if (response.status === 200) {
      setDisabledForm(true);
      openNotificationWithIcon(
        "success",
        `${response.message.en} / ${response.message.id}`
      );
      actionPrincipalAccount();
      setDisabledButtonEdit(false);
    } else {
      setDisabledButtonEdit(false);
      openNotificationWithIcon(
        "error",
        `${response.message.en} / ${response.message.id}`
      );
    }
  }

  function handleChangeSubmit(params) {
    const {
      payor_name,
      email,
      phone_number,
      established_on,
      fee_absorption,
      freeze_status,
      bank_name,
      bank_branch,
      account_name,
      account_number,
    } = params;
    const paramsSubmit = {
      payor_name,
      email,
      phone_number: phone_number.toString(),
      established_on: moment(established_on).format("YYYY-MM-DD"),
      fee_absorption,
      freeze_status,
      bank_name,
      bank_branch,
      account_name,
      account_number : account_number.toString(),
    };
    actionSubmitDetails(paramsSubmit);
  }

  function actionChangeCancel() {
    window.location.reload();
  }

  return (
    <Formik
      initialValues={{
        payor_name:
          principalAccount && principalAccount.vendor_detail.payor_name,
        email: principalAccount && principalAccount.vendor_detail.email,
        phone_number:
          principalAccount && principalAccount.vendor_detail.phone_number,
        fee_absorption:
          principalAccount && principalAccount.vendor_detail.fee_absorption,
        freeze_status:
          principalAccount && principalAccount.vendor_detail.freeze_status,
        established_on:
          principalAccount && principalAccount.vendor_detail.established_on,
        company_monthly_limit:
          principalAccount &&
          principalAccount.vendor_detail.company_monthly_limit,
        bank_name: principalAccount && principalAccount.vendor_detail.bank_name,
        bank_branch:
          principalAccount && principalAccount.vendor_detail.bank_branch,
        account_name:
          principalAccount && principalAccount.vendor_detail.account_name,
        account_number:
          principalAccount && principalAccount.vendor_detail.account_number,
      }}
      enableReinitialize
      validationSchema={schemaDetails}
      validateOnChange={false}
      onSubmit={(values) => {
        handleChangeSubmit(values);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={24} md={10}>
              <Form.Item
                label="PIC NAME"
                validateStatus={errors.payor_name && "error"}
                help={errors.payor_name}
              >
                <Input
                  size="large"
                  disabled={disabledForm}
                  name="payor_name"
                  onChange={handleChange}
                  value={values.payor_name}
                />
              </Form.Item>
              <Form.Item
                label={"PIC PHONE NUMBER"}
                validateStatus={errors.phone_number && "error"}
                help={errors.phone_number}
              >
                {" "}
                <Input
                  size="large"
                  type="number"
                  name="phone_number"
                  disabled={disabledForm}
                  onChange={handleChange}
                  value={values.phone_number}
                />
              </Form.Item>
              <Form.Item
                label={"EMAIL"}
                validateStatus={errors.email && "error"}
                help={errors.email}
              >
                <Input
                  type="email"
                  size="large"
                  disabled={disabledForm}
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                />
              </Form.Item>
              <Form.Item
                label={"FREEZE STATUS "}
                validateStatus={errors.freeze_status && "error"}
                help={errors.freeze_status}
              >
                <Input
                  disabled={true}
                  size="large"
                  name="freeze_status"
                  onChange={handleChange}
                  value={values.freeze_status}
                />
              </Form.Item>
              <Form.Item
                label={"FEE ABSORPTION"}
                validateStatus={errors.fee_absorption && "error"}
                help={errors.fee_absorption}
                onBlur={handleBlur}
              >
                <NumberFormat
                  style={{
                    height: 40,
                    border: "1px solid #d9d9d9",
                    width: "100%",
                    padding: 10,
                    color:  "rgba(0,0,0,.25)",
                  }}
                  disabled={true}
                  name="fee_absorption"
                  type="tel"
                  format="### %"
                  onChange={(value, option) =>
                    actionChangeFeeAbsorption(value, option, setFieldValue)
                  }
                  value={values.fee_absorption}
                />
              </Form.Item>
              <Form.Item
                label={"ESTABLISHED ON"}
                validateStatus={errors.established_on && "error"}
                help={errors.established_on}
                onBlur={handleBlur}
              >
                <DatePicker
                  name="established_on"
                  style={{ width: "100%" }}
                  disabled={disabledForm}
                  defaultValue={
                    values.established_on
                      ? moment(values.established_on, "YYYY-MM-DD")
                      : ""
                  }
                  defaultPickerValue={moment("1990-01-01", "YYYY-MM-DD")}
                  placeholder="1990-01-01"
                  format="YYYY-MM-DD"
                  onChange={(value, option) =>
                    actionChangeEstablishedOn(value, option, setFieldValue)
                  }
                  size="large"
                />
              </Form.Item>
              {/* <Form.Item
                label={"COMPANY MONTHLY LIMIT"}
                validateStatus={errors.company_monthly_limit && "error"}
                help={errors.company_monthly_limit}
              >
                <Input
                  disabled={disabledForm}
                  size="large"
                  name="company_monthly_limit"
                  onChange={handleChange}
                  value={
                    values.company_monthly_limit &&
                    currencyRupiah(values.company_monthly_limit, true)
                  }
                />
              </Form.Item> */}
            </Col>
            <Col xs={24} md={10} style={{ paddingLeft: 10 }}>
              <Form.Item
                label={"BANK BRANCH"}
                validateStatus={errors.bank_branch && "error"}
                help={errors.bank_branch}
              >
                {" "}
                <Input
                  size="large"
                  type="text"
                  name="bank_branch"
                  placeholder="BCA"
                  disabled={
                    principalAccount &&
                    principalAccount.vendor_detail.bank_branch
                      ? true
                      : disabledForm
                  }
                  onChange={handleChange}
                  value={values.bank_branch}
                />
              </Form.Item>
              <Form.Item
                label={"BANK NAME"}
                validateStatus={errors.bank_name && "error"}
                help={errors.bank_name}
              >
                {" "}
                <Input
                  size="large"
                  type="text"
                  name="bank_name"
                  placeholder="KCP MEGA KUNINGAN"
                  disabled={
                    principalAccount && principalAccount.vendor_detail.bank_name
                      ? true
                      : disabledForm
                  }
                  onChange={handleChange}
                  value={values.bank_name}
                />
              </Form.Item>
              <Form.Item
                label={"ACCOUNT NAME"}
                validateStatus={errors.account_name && "error"}
                help={errors.account_name}
              >
                {" "}
                <Input
                  size="large"
                  type="text"
                  name="account_name"
                  placeholder="JHON DOE"
                  disabled={
                    principalAccount &&
                    principalAccount.vendor_detail.account_name
                      ? true
                      : disabledForm
                  }
                  onChange={handleChange}
                  value={values.account_name}
                />
              </Form.Item>
              <Form.Item
                label={"ACCOUNT NUMBER"}
                validateStatus={errors.account_number && "error"}
                help={errors.account_number}
              >
                {" "}
                <Input
                  size="large"
                  type="number"
                  name="account_number"
                  placeholder="123456789"
                  disabled={
                    principalAccount &&
                    principalAccount.vendor_detail.account_number
                      ? true
                      : disabledForm
                  }
                  onChange={handleChange}
                  value={values.account_number}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={4}>
              <div style={{ position: "absolute", top: 0, right: 0 }}>
                {" "}
                <Button
                  type="primary"
                  disabled={disabledButtonEdit}
                  onClick={() => actionChangeEnabledDisabled()}
                  size={"large"}
                >
                  EDIT COMPANY
                </Button>
              </div>
              <div style={{ position: "absolute", bottom: 50, right: 0 }}>
                <Button
                  size="large"
                  style={{ marginRight: 20 }}
                  disabled={disabledForm}
                  onClick={() => actionChangeCancel()}
                >
                  {" "}
                  CLOSE
                </Button>
                <Button
                  type="primary"
                  disabled={disabledForm}
                  htmlType="submit"
                  size={"large"}
                >
                  SAVE
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
}
export default Details;
