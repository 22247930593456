import React from 'react';
import {
  Button
} from "reactstrap";
import { connect } from 'react-redux';


class ErrorContent extends React.Component {
  render() {

    const { language } = this.props;

    return (
      <div className="error-box">
        <div className="error-box__head">
          <h1>500</h1>
        </div>
        <div className="error-box__content">
          <h2>{language.error.serverError}</h2>
          <p>{language.error.serverDesc}</p>
          <Button color="primary" href="/">{language.error.errorButton}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  language: state.init.language
});

export default connect(mapStateToProps, null)(ErrorContent);
