const KOINREVOLVER_LEADS = "v1/koinrevolver/principal/";
const PRINCIPAL_LEADS = `${KOINREVOLVER_LEADS}${"leads"}`;
const PRINCIPAL_ACCOUNT = `${KOINREVOLVER_LEADS}${"account"}`;
const KOINREVOLVER_PRINCIPAL_LEADS_UPLOAD = `${KOINREVOLVER_LEADS}${"leads/"}${"upload"}`;
const KOINREVOLVER_UPDATE =`${KOINREVOLVER_LEADS}${"vendor/update/"}`;
const KOINREVOLVER_DETAILS =`${KOINREVOLVER_LEADS}${"vendor-detail/upsert/"}`
const LEADS_UPLOAD_TEMPLATE = `${KOINREVOLVER_LEADS}bulk-leads-template`
const PROVINCE = "v1/thirdparty/location/province?keyword="
const CITY = "v1/thirdparty/location/city?keyword=";

export const PATH_PRINCIPAL = {
  PRINCIPAL_LEADS,
  KOINREVOLVER_PRINCIPAL_LEADS_UPLOAD,
  KOINREVOLVER_UPDATE,
  KOINREVOLVER_DETAILS,
  LEADS_UPLOAD_TEMPLATE,
  PRINCIPAL_ACCOUNT,
  PROVINCE,
  CITY
};
