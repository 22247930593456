import styled from "styled-components";

export const Section = styled.div`
  .kw-dashboard-principalLabel {
    color: #c1c1c1;
  }

  .kw-dashboard-principalPargphOne {
    color: #7ea2b3;
  }

  .kw-dashboard-principalPargphTwo {
    color: #6DB2F2;
  }

  .kw-dashboard-principalPargphThree {
    color: #566068;
  }

  .kw-dashboard-principalPargph {
    font-weight: bold;
    font-size: 28px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.41px;
  }

  .cardPrincipal {
    .ant-card-body {
      padding: 25px;
    }
  }
`;
