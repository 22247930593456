import React from 'react';

import { Section } from './style';
import NotFound from './components/NotFound';
import ErrorContent from './components/ErrorContent';

class Container extends React.Component {
  render() {

    const { error } = this.props;

    if (error === 500) {
      return (
        <Section className="error-section">
          <div className="container">
            <ErrorContent />
          </div>
        </Section>
      )
    }

    return (
      <Section className="error-section">
        <div className="container">
          <NotFound />
        </div>
      </Section>
    )
  }
}

export default Container;
