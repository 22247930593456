import styled from "styled-components";

export const Section = styled.div`
  .kw-login-imageTop {
    position: absolute;
    left: -100px;
    max-width: 110px;
    top: 170px;
  }

  .kw-image-banneShape {
    position: absolute;
    top: 365px;
    left: 362px;
    z-index: 1;
  }

  .kw-login {
    &__main-label {
      padding-top: 30px;
      color: #2b486d;
      font-size: 25px;
      text-align: center;
      margin-bottom: 0px;
    }
    &__second-label {
      color: #566068;
      font-size: 20px;
      text-align: center;
      display: block;
      margin-bottom: 50px;
      font-weight: bold;
    }
  }
`;
