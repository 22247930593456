import styled from "styled-components";

export const Section = styled.section`
  position: relative;
  display: block;
  padding: 40px 0;
  .error-box {
    max-width: 480px;
    display: block;
    padding: 10% 0;
    margin: 0 auto;
    &__head {
      text-align: center;
      h1 {
        font-size: 88px;
        line-height: 88px;
        font-weight: 400;
        margin: 5px 0 0;
      }
    }
    &__content {
      text-align: center;
      h2 {
        font-size: 32px;
        font-weight: 400;
        margin: 0 0 10px;
      }
      p {
        font-size: 16px;
        line-height: 21px;
        display: block;
        margin: 0 0 20px;
      }
    }
  }
`;
