import styled from "styled-components";

export const Section = styled.div`
  .kw-dashboardLayout {
    .kw-dashboard-sidebarNavigation {
      background:#43425d;
    }
    .ant-layout-sider-children {
      background: #43425d;
    }
    &__sidebar {
      max-width: 124px !important;
      min-width: 124px !important;
      width: 125px !important;
      background: #43425d;
    }
    .ant-menu-submenu > .ant-menu {
      background: #43425d;
    }
    .ant-menu-inline .ant-menu-item:not(:last-child) {
      color: #ffffff;
      height: 70px;
      text-align: center;
    }
    .ant-menu-inline .ant-menu-item {
      color: #ffffff;
      height: 70px;
      text-align: center;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background: #010101;
    }
    .ant-menu-inline .ant-menu-item::after {
      border-left: 3px solid yellow;
      border-right: unset;
    }
  }
`;
