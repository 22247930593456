import React, { useState, useEffect } from "react";
import { Table, Button, Modal, notification, Spin, Alert } from "antd";
import "./style.css";
import currencyRupiah from "../../library/currency";
import InvoiceOnGoingDashboard from "../../components/InvoiceOnGoingDashboard";
import { useRootContext } from "../../hoc/RootContext";
import moment from "moment";
import { convertToCategoryName } from "../../library/regex";
import loansReaderRepository from "../../repository/Loans/loans";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

const openNotificationWithIcon = (type, message) => {
  notification[type]({
    message: message,
    duration: 100,
  });
};

export default function DashboardOnGoing(props) {
  const [isShowModalViewInvoice, setIsShowModalViewInvoice] = useState(false);
  const [isShowLoadingOnChange, setIsShowLoadingOnChange] = useState(false);
  const [isShowLoadingConfirm, setIsShowLoadingConfirm] = useState(false);
  const [isShowLoadingDelete, setIsShowLoadingDelete] = useState(false);
  const accountChange =
    JSON.parse(window.localStorage.getItem("account-change")) !== null &&
    JSON.parse(window.localStorage.getItem("account-change")).data;
  const {
    vendor: { vendor_uuid, vendor_name, vendor_type, vendor_code },
  } = accountChange;
  const {
    onGoingList,
    isAuthenticated,
    isShowAlertOnGoing,
    actionOnGoingList,
    onGoingListError,
    history,
  } = useRootContext();

  useEffect(() => {
    isAuthenticated === false && history.push("/"); // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    actionOnGoingList(vendor_uuid, setIsShowLoadingOnChange); // eslint-disable-next-line
  }, []);

  function handleChangeShowHideModalViewInvoice() {
    setIsShowModalViewInvoice(!isShowModalViewInvoice);
  }

  async function actionChangeRejectOnGoing(paramsId) {
    let onGoing = await loansReaderRepository.actionRejectList({
      paramsId: paramsId,
      loading: setIsShowLoadingDelete,
    });
    if (onGoing.status === 200) {
      actionOnGoingList(vendor_uuid, setIsShowLoadingOnChange);
      openNotificationWithIcon("success", "List borrower berhasil Reject");
    } else {
      openNotificationWithIcon("error", "List borrower tidak berhasil Reject");
    }
  }

  async function actionChangeConfirmOnGoing(paramsId) {
    let params = {
      vendor_type: vendor_type,
      vendor_code: vendor_code,
    };
    let onGoing = await loansReaderRepository.actionConfirmList({
      paramsId: paramsId,
      loading: setIsShowLoadingConfirm,
      params: params,
    });
    if (onGoing.status === 200) {
      actionOnGoingList(vendor_uuid, setIsShowLoadingOnChange);
      openNotificationWithIcon("success", "List borrower berhasil Confirm");
    } else {
      openNotificationWithIcon("error", "List borrower tidak berhasil Confirm");
    }
  }

  function showDeleteConfirm(paramsId) {
    confirm({
      title: "Are you sure Reject this task?",
      icon: <ExclamationCircleOutlined />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        actionChangeRejectOnGoing(paramsId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  function showConfirm(paramsId) {
    confirm({
      title: "Do you Want to Confirm these items?",
      icon: <ExclamationCircleOutlined />,
      content: "Some descriptions",
      onOk() {
        actionChangeConfirmOnGoing(paramsId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const onCloseAlertOnGoing = (e) => {
    console.log(e, "I was closed.");
  };

  const dataArraySouces =
    onGoingList &&
    onGoingList.length > 0 &&
    onGoingList.map((data, index) => schemaList(data, index));

  function schemaList(
    {
      borrower_code,
      borrower_name,
      due_date,
      loan_code,
      invoice_number,
      loan_id,
      user_id,
      loan_amount,
      invoice_deadline,
      loan_status,
      last_action_status,
    },
    index
  ) {
    return {
      index: index,
      borrower_code,
      borrower_name,
      due_date,
      loan_code,
      invoice_number,
      loan_id,
      user_id,
      loan_amount,
      invoice_deadline,
      loan_status,
      last_action_status,
    };
  }

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record) => (
        <span>{dataArraySouces.indexOf(record) + 1}</span>
      ),
    },
    {
      title: "Borrower Name ",
      dataIndex: "borrower_name",
      key: "borrower_name",
    },
    {
      title: "Borrower ID",
      dataIndex: "borrower_code",
      key: "borrower_code",
    },
    {
      title: "Loan ID",
      dataIndex: "loan_code",
      key: "loan_code",
    },
    {
      title: "Invoice No",
      dataIndex: "invoice_number",
      key: "invoice_number",
    },
    {
      title: "Loan Amount",
      dataIndex: "loan_amount",
      key: "loan_amount",
      render: (text) => <span>{currencyRupiah(text)}</span>,
    },
    {
      title: "Due Date",
      dataIndex: "invoice_deadline",
      key: "invoice_deadline",
      render: (text) => <span>{moment(text).format("DD MMM YYYY")}</span>,
    },
    {
      title: "Status",
      dataIndex: "loan_status",
      key: "loan_status",
      render: (text) => <span>{convertToCategoryName(text)}</span>,
    },
    {
      title: "Last Action Date",
      dataIndex: "lastactiondate",
      key: "lastactiondate",
      render: (text) => <span>{moment(text).format("DD MMM YYYY")}</span>,
    },
    // {
    //   title: "Invoice",
    //   dataIndex: "invoice",
    //   key: "invoice",
    //   render: (text, value, index) => (
    //     <span
    //       onClick={handleChangeShowHideModalViewInvoice}
    //       style={{
    //         color: "#43ADF7",
    //         textDecorationLine: "underline",
    //         fontWeight: 555,
    //         cursor: "pointer",
    //       }}
    //     >
    //       View
    //     </span>
    //   ),
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, value, index) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {["unfinished", "assessment_waiting_for_review"].includes(
            value.loan_status
          ) ? (
            <React.Fragment>
              <Spin spinning={isShowLoadingConfirm} style={{ width: "100%" }}>
                <Button
                  onClick={() => showConfirm(value.loan_id)}
                  size="large"
                  style={{
                    background: "#ffffff",
                    border: " 1px solid #43adf7",
                    boxSizing: "border-box",
                    borderRadius: 5,
                    color: "#43ADF7",
                    width: "90%",
                  }}
                >
                  Confirm
                </Button>
              </Spin>
              <Spin spinning={isShowLoadingDelete} style={{ width: "100%" }}>
                <Button
                  size="large"
                  onClick={() => showDeleteConfirm(value.loan_id)}
                  style={{
                    background: "#ffffff",
                    border: " 1px solid #FF8A9B",
                    boxSizing: "border-box",
                    borderRadius: 5,
                    color: "#FF8A9B",
                  }}
                >
                  Reject{" "}
                </Button>{" "}
              </Spin>
            </React.Fragment>
          ) : (
            convertToCategoryName(value.loan_status)
          )}
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div
        className="container-fluid"
        style={{
          marginTop: 50,
          marginLeft: 10,
          marginBottom: 50,
        }}
      >
        <h3 style={{ marginBottom: 40, color: "#2B486D", marginLeft: 30 }}>
          Principal :{" "}
          {vendor_name.charAt(0).toUpperCase() + vendor_name.slice(1)}
        </h3>
        {isShowAlertOnGoing && (
          <Alert
            message={onGoingListError}
            type="error"
            style={{ marginBottom: 20 }}
            closable
            onClose={onCloseAlertOnGoing}
          />
        )}
        <Table
          dataSource={onGoingList && onGoingList ? dataArraySouces : undefined}
          scroll={{ x: 1555 }}
          loading={isShowLoadingOnChange}
          rowKey={(record) => record.index}
          columns={columns}
          size="large"
        />
      </div>
      <InvoiceOnGoingDashboard
        isShowModalViewInvoice={isShowModalViewInvoice}
        handleChangeShowHideModalViewInvoice={
          handleChangeShowHideModalViewInvoice
        }
      />
    </React.Fragment>
  );
}
