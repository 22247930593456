import React, { useState, useEffect } from "react";
import { Section } from "./style";

export default function LoginLayout({ children }) {
  const [heightSidebar, setHeightSideBar] = useState();
  const updateHeightSidebar = () => {
    setHeightSideBar(window.innerHeight);
  };
  useEffect(() => {
    updateHeightSidebar(); // eslint-disable-next-line
  }, [window.innerHeight]);
  return (
    <Section>
      <div className="kw-loginLayout" style={{ height: heightSidebar }}>
        <div className="kw-loginLayout__main">{children}</div>
      </div>
    </Section>
  );
}
