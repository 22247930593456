const LOGIN = "/";
const DASHBOARD_PRINCIPAL = "/dashboard/principal";
const DASHBOARD_LEADS = "/dashboard/leads";
const DASHBOARD_SUCCES_FEE = "/dashboard/succes-fee";
const DASHBOARD_ON_GOING = "/dashboard/on-going";
const DASHBOARD_HELP = "/dashboard/help";
const DASHBOARD_SETTINGS = "/dashboard/settings";
const NOT_FOUND = "*";

const PATH_URL = {
  LOGIN: LOGIN,
  DASHBOARD_PRINCIPAL: DASHBOARD_PRINCIPAL,
  DASHBOARD_LEADS: DASHBOARD_LEADS,
  DASHBOARD_SUCCES_FEE: DASHBOARD_SUCCES_FEE,
  DASHBOARD_ON_GOING: DASHBOARD_ON_GOING,
  DASHBOARD_SETTINGS : DASHBOARD_SETTINGS,
  DASHBOARD_HELP: DASHBOARD_HELP,
  NOT_FOUND: NOT_FOUND,
};

export default PATH_URL;
