import React, { useEffect, useState } from "react";
import { Modal, Button, Spin, Alert } from "antd";
import { useDropzone } from "react-dropzone";
import { actionCommonUploads } from "../../stores/actions/upload";
import {
  actionUploadLeads,
  actionButtonSubmit,
} from "../../stores/actions/leads";
import { Section } from "./style";
import "./style.css";
import { connect } from "react-redux";
import { useRootContext } from "../../hoc/RootContext";
import firebases from "../../firebase";
import "firebase/analytics";
import leadsRepository from "../../repository/Leads/leads";
import { CSVLink } from "react-csv";

const allowedFiles = [".csv", "application/vnd.ms-excel", "text/csv"];

function UploadLeadsDashboard(props) {
  const { actionPrincipalAccount, principalAccount } = useRootContext();
  const [files, setFiles] = useState([]);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [textErrorUploadType, setTextErrorUploadType] = useState(false);
  const [isShowErrorUploadLeads, setIsShowErrorUploadLeads] = useState(false);
  const [csv, setCsv] = useState();
  const [filePath, setFilePath] = useState("");
  const [isCsvUpload, setIsCsvUpload] = useState(false);
  const authData = JSON.parse(localStorage.getItem("authData"));
  const {
    isShowUploadModal,
    handleChangeIsShowUpload,
    actionCommonUploads,
    actionUploadLeads,
    upload,
    uploadLeads,
  } = props;
  const analytics = firebases.analytics();

  useEffect(() => {
    leadsRepository.getDownloadCsvTemplateLeads({}).then((res) => {
      setCsv(res);
      if (res.status !== 200) {
        // open;
      }
    });
    actionPrincipalAccount(); // eslint-disable-next-line
  }, []);

  const onDropRejected = () => {
    setTextErrorUploadType(true);
    setIsCsvUpload(false);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: allowedFiles,
    noClick: true,
    noKeyboard: true,
    onDropRejected: onDropRejected,
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        if (file.type === "application/vnd.ms-excel") {
          setIsCsvUpload(true);
        }
        setFilePath(file.path);
        setIsShowErrorUploadLeads(false);
        actionChangeUploadLeadsDashboard(file);
      });
      setTextErrorUploadType(false);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  function actionChangeUploadLeadsDashboard(values) {
    let fileReader = new FileReader();
    fileReader.onloadend = () => {
      let params = {
        base64: [fileReader.result],
        email: authData.email,
      };
      actionCommonUploads(params, setIsShowLoading);
    };
    fileReader.readAsDataURL(values);
  }

  const submitUploadList = () => {
    let params = {
      csv_url: upload?.data[0],
      // vendor_uuid:
      //   principalAccount?.data?.vendor?.vendor_uuid,
    };
    actionUploadLeads(
      params,
      handleChangeIsShowUpload,
      setIsShowLoading,
      setIsShowErrorUploadLeads,
      setIsCsvUpload
    );
    analytics.logEvent("KI_vendor_leads_uploads_web", {
      vendor_uuid: principalAccount?.data?.vendor?.vendor_uuid,
    });
  };

  // const thumbs = files.map((file) => (
  //   <div style={thumb} key={file.name}>
  //     <div style={thumbInner}>
  //       <img src={file.preview} style={img} alt="" />
  //     </div>
  //   </div>
  // ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  function handleChangeIsShowUploadAction(value) {
    handleChangeIsShowUpload(value);
    setIsCsvUpload(false);
  }

  return (
    <Modal
      title="Bulk Upload"
      visible={isShowUploadModal}
      // onOk={() => submitUploadList()}
      onCancel={() => handleChangeIsShowUploadAction("cancel")}
      footer={[
        <Button key="back" onClick={() => handleChangeIsShowUploadAction()}>
          Back
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={
            principalAccount?.data?.vendor?.vendor_uuid &&
            upload.data &&
            upload.data[0]
              ? false
              : true
          }
          onClick={() => submitUploadList()}
        >
          Submit
        </Button>,
      ]}
    >
      <Spin spinning={isShowLoading}>
        <Section>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <img
                src={require("../../assets/image/icon-upload-leads.png")}
                alt=""
              />
              <p style={{ fontWeight: "bold" }}>DRAG CSV FILE HERE</p>
              <Button
                type="primary"
                size={"large"}
                className="kw-dashboardLeads-button"
                onClick={open}
              >
                CHOOSE FILE
              </Button>
              {textErrorUploadType && (
                <Alert
                  message={"Upload Hanya CSV,Ms-excel"}
                  type="error"
                  style={{ marginTop: 5 }}
                  showIcon
                />
              )}
            </div>
          </div>
          <Button style={{ marginBottom: 20, marginTop: 20 }}>
            <CSVLink data={csv} filename={`${authData.email}.csv`}>
              Download Template
            </CSVLink>
          </Button>
          {isCsvUpload && (
            <div>
              <img
                src={require("../../assets/image/image-excel.png")}
                alt=""
                style={{ height: 100 }}
              />
              <p>{filePath}</p>
            </div>
          )}
          {isShowErrorUploadLeads && (
            <Alert
              message={`${uploadLeads.message.en} / ${uploadLeads.message.id}`}
              type="error"
              style={{ marginTop: 15 }}
              showIcon
            />
          )}
        </Section>
      </Spin>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  uploadLeads: state.leads.uploadLeads,
  upload: state.upload.upload,
});

export default connect(mapStateToProps, {
  actionCommonUploads,
  actionUploadLeads,
  actionButtonSubmit,
})(UploadLeadsDashboard);
