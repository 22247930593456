import React from "react";
import { Row, Col, Card } from "antd";
import currencyRupiah from "../../library/currency";

export default function CardDashboardPrincipal() {
  return (
    <React.Fragment>
      <Row>
        <Col md={4}>
          <Card className="cardPrincipal">
            <label className="kw-dashboard-principalLabel">Total Leads</label>
            <strong className="kw-dashboard-principalPargphOne kw-dashboard-principalPargph">
              30
            </strong>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="cardPrincipal" style={{ marginLeft: 10 }}>
            <label className="kw-dashboard-principalLabel">
              Total Disbursed/Case
            </label>
            <strong className="kw-dashboard-principalPargphTwo kw-dashboard-principalPargph">
              25
            </strong>
          </Card>
        </Col>
        <Col md={7}>
          <Card className="cardPrincipal" style={{ marginLeft: 10 }}>
            <label className="kw-dashboard-principalLabel">
              Total Disbursed/amount
            </label>
            <strong className="kw-dashboard-principalPargphThree kw-dashboard-principalPargph">
              {currencyRupiah(20000000)}
            </strong>
          </Card>
        </Col>
        <Col md={7}>
          <Card className="cardPrincipal" style={{ marginLeft: 10 }}>
            <label className="kw-dashboard-principalLabel">
              Succsess Fee Earned
            </label>
            <strong className="kw-dashboard-principalPargphThree kw-dashboard-principalPargph">
              {currencyRupiah(280000000)}
            </strong>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
