import {
    apiGetWithToken
} from "../../services";
import {
    PATH_KOININVOICE
} from "../../services/path/koininvoice";

async function getCategoryLimitApplication(props) {
    let response = "";
    let loading = props.loading ? props.loading : function () {};
    loading(true);
    try {
        response = await apiGetWithToken(`${PATH_KOININVOICE.LIMIT_APPLICATION_BUSINESS}`);
        loading(false);
    } catch (error) {
        response = error.response;
        loading(false);
    }
    return response && response.data;
}

const koininvoice = {
    getCategoryLimitApplication
};

export default koininvoice;