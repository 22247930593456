import { reducer as reduxReducer } from "redux-form";
import { combineReducers } from "redux";
import leads from "./leads";
import upload from "./upload";

const reducers = combineReducers({
  form: reduxReducer,
  leads: leads,
  upload : upload
});

export default reducers;
