const BUTTON_SUBMIT_UPLOAD_FORM = "BUTTON_SUBMIT_UPLOAD_FORM"
const BUTTON_SUBMIT_UPLOAD_FORM_UPLOAD = "BUTTON_SUBMIT_UPLOAD_FORM_UPLOAD"
const GET_LEADS_SUCSESS = "GET_LEADS_SUCSESS";
const GET_LEADS_SUCSESS_ERROR = "GET_LEADS_SUCSESS_ERROR";
const POST_LEADS_SUCSESS = "POST_LEADS_SUCSESS";
const POST_LEADS_ERROR = "POST_LEADS_ERROR";
const POST_UPLOAD_LEADS_SUCSESS = "POST_UPLOAD_LEADS_SUCSESS";
const POST_UPLOAD_LEADS_ERROR = "POST_UPLOAD_LEADS_ERROR";
const COMMON_UPLOAD_SUCSESS = "COMMON_UPLOAD_SUCSESS";
const COMMON_UPLOAD_ERROR = "COMMON_UPLOAD_ERROR";
const PROVINCE_SUCSESS = "PROVINCE_SUCSESS";
const PROVINCE_ERROR = "PROVINCE_ERROR";
const CITY_SUCSESS = "CITY_SUCSESS";
const CITY_ERROR = "CITY_ERROR";

const TYPE = {
  GET_LEADS_SUCSESS,
  GET_LEADS_SUCSESS_ERROR,
  POST_LEADS_SUCSESS,
  POST_LEADS_ERROR,
  POST_UPLOAD_LEADS_SUCSESS,
  POST_UPLOAD_LEADS_ERROR,
  COMMON_UPLOAD_SUCSESS,
  COMMON_UPLOAD_ERROR,
  BUTTON_SUBMIT_UPLOAD_FORM,
  BUTTON_SUBMIT_UPLOAD_FORM_UPLOAD,
  PROVINCE_SUCSESS,
  PROVINCE_ERROR,
  CITY_SUCSESS,
  CITY_ERROR
};

export default TYPE;
