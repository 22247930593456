import React, { useEffect } from "react";
import { Card, Tabs } from "antd";
import { useRootContext } from "../../hoc/RootContext";
import { DashboardSettingsStyle } from "./style";
import General from "./General";
import Details from "./Details";

const { TabPane } = Tabs;

function DashboardSettings(props) {
  const { history, isAuthenticated } = useRootContext();
  const accountChange =
    JSON.parse(window.localStorage.getItem("account-change")) !== null &&
    JSON.parse(window.localStorage.getItem("account-change")).data;
  const {
    vendor: { vendor_name },
  } = accountChange;

  useEffect(() => {
    isAuthenticated === false && history.push("/"); // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <DashboardSettingsStyle>
      <div className="kw-settingsWrapper">
        <h3 style={{ color: "#2B486D", marginBottom: 15 }}>
          Principal :{" "}
          {vendor_name.charAt(0).toUpperCase() + vendor_name.slice(1)}
        </h3>
        <Card>
          <Tabs defaultActiveKey="1" size="large" style={{ marginBottom: 32 }}>
            <TabPane tab="General" key="1">
              <General
                principalAccount={
                  JSON.parse(window.localStorage.getItem("account-change")) !==
                    null &&
                  JSON.parse(window.localStorage.getItem("account-change")).data
                }
              />
            </TabPane>
            <TabPane tab="Details" key="2">
              <Details
                principalAccount={
                  JSON.parse(window.localStorage.getItem("account-change")) !==
                    null &&
                  JSON.parse(window.localStorage.getItem("account-change")).data
                }
              />
            </TabPane>
          </Tabs>
        </Card>
      </div>
    </DashboardSettingsStyle>
  );
}

export default DashboardSettings;
