import styled from "styled-components";

export const Section = styled.div`
  .kw-dashboardHelps {
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 10px;
    strong {
      display: block;
    }
    &__callFindUs {
      width: 300px;
      color: #000000;
    }
    &__addressKw {
      width: 300px;
      color: #000000;
    }
    h4 {
      margin-top: 30px;
      margin-bottom: 15px;
      font-size: 30px;
      color: #2d2d2d;
      font-size: 38px;
    }
    &__heading-text {
      margin-bottom: 40px;
      margin-left: 30px;
      color: #2b486d;
    }
  }
`;
