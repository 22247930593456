import styled from "styled-components";

export const DashboardSettingsStyle = styled.div`
  .kw-settingsWrapper {
    padding: 20px;
    .ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
      padding: 10px 50px;
    }
    .ant-tabs-large>.ant-tabs-nav .ant-tabs-tab{
      padding: 10px 0;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #ffffff;
      padding: 0px 50px;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
      color: #ffffff;
      background: #1890ff;
    }
    strong {
      margin-bottom: 25px;
      display: block;
      font-weight: 555;
      font-size: 28px;
      line-height: 34px;
      letter-spacing: 0.337647px;
      color: #000000;
    }
  }
`;
