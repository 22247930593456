import styled from "styled-components";

export const Section = styled.div`
  .modal-wrapper {
    padding: 15px 0;    
    font-family: Source Sans Pro;
    font-style: normal;
  }
  .kw-modal-img {
    margin: 35px 20px;
    text-align: center;
  }
  .kw-modal-title {
    font-weight: bold;
    font-size: 34px;
    text-align: center;
    letter-spacing: 0.41px;
    color: #2B486D;
    padding: 0 60px;
  }
  .kw-modal-body {
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.41px;
    color: #575756;
    padding: 0 30px;
  }
  .kw-modal-text {
    color: #ababab;
    text-align: center;
  }
`;
