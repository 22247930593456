import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { useRootContext } from "../../hoc/RootContext";
import { Section } from "./style";
import HeaderKoinworks from "../../components/Header";
import PATH_URL from "../../routers/path";

const { Content, Sider } = Layout;

export default function DashboardLayout({ children }) {
  const { history } = useRootContext();
  const [heightSidebar, setHeightSideBar] = useState();
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    getTabActive();
    updateHeightSidebar()// eslint-disable-next-line
  }, [window.innerHeight]);


  const updateHeightSidebar = () => {
    setHeightSideBar(window.innerHeight);
  };

  function getTabActive() {
    const routeTab = window.location.pathname;
    const splitRoute = routeTab.split("/");
    const tabActive = splitRoute[splitRoute.length - 1];
    setActiveTab(tabActive);
  }
  
  return (
    <Section>
      <Layout>
        <div className="kw-dashboardLayout">
          <div className="kw-dashboardLayout__content-wrapper">
            <Layout>
              <Sider
                style={{ height: heightSidebar }}
                className="kw-dashboardLayout__sidebar"
              >
                <div className="kw-dashboard-sidebarNavigation">
                  <Menu
                    mode="inline"
                    defaultOpenKeys={["principal"]}
                    selectedKeys={[activeTab]}
                    style={{
                      width: 125,
                      background: "#43425D"
                    }}
                  >
                    {/* <Menu.Item
                      key="principal"
                      onClick={() => history.push(PATH_URL.DASHBOARD_PRINCIPAL)}
                    >
                      <img
                        src={require("../../assets/image/icon-dashboard-principal.png")}
                        alt=""
                      />
                      <br />
                      Dashboard
                    </Menu.Item> */}
                    <Menu.Item
                      key="leads"
                      onClick={() => history.push(PATH_URL.DASHBOARD_LEADS)}
                    >
                      <img
                        src={require("../../assets/image/icon-dashboard-leads.png")}
                        alt=""
                      />
                      <br />
                      Leads
                    </Menu.Item>
                    <Menu.Item
                      key="on-going"
                      onClick={() => history.push(PATH_URL.DASHBOARD_ON_GOING)}
                    >
                      {" "}
                      <img
                        src={require("../../assets/image/icon-dashboard-ongoing.png")}
                        alt=""
                      />
                      <br />
                      Ongoing
                    </Menu.Item>
                    <Menu.Item
                      key="succes-fee"
                      onClick={() =>
                        history.push(PATH_URL.DASHBOARD_SUCCES_FEE)
                      }
                    >
                      <img
                        src={require("../../assets/image/icon-dashboard-succesfee.png")}
                        alt=""
                      />
                      <br />
                      Successfee
                    </Menu.Item>
                    <Menu.Item
                      key="settings"
                      onClick={() => history.push(PATH_URL.DASHBOARD_SETTINGS)}
                    >
                      <img
                        src={require("../../assets/image/icon-dashboard-settings.png")}
                        alt=""
                      />
                      <br />
                      Settings
                    </Menu.Item>
                    <Menu.Item
                      key="help"
                      onClick={() => history.push(PATH_URL.DASHBOARD_HELP)}
                    >
                      <img
                        src={require("../../assets/image/icon-dashboard-help.png")}
                        alt=""
                      />
                      <br />
                      Helps
                    </Menu.Item>
                  </Menu>
                </div>
              </Sider>
              <Layout style={{ background: "#E5E5E5",height: heightSidebar }}>
                <HeaderKoinworks />
                <Content style={{height: heightSidebar}}>{children}</Content>
              </Layout>
            </Layout>
          </div>
        </div>
      </Layout>
    </Section>
  );
}
