let currencyFormatter = require("currency-formatter");

const currencyRupiah = (price, isShow = false) => {
  return currencyFormatter.format(price, {
    symbol: isShow === true ? "IDR " : "Rp ",
    decimal: ",",
    thousand: ",",
    precision: 0,
    //format: "%v %s" // %s is the symbol and %v is the value
  });
};

export default currencyRupiah;
