import dispatchType from "./dispatchType";
import uploadRepository from "../../repository/Uploads/uploads";

export const actionButtonSubmitUpload = request => async dispatch => {
  try {
    dispatch(dispatchType.buttonBackSubmitUpload())
  } catch (error){
    console.log(error);
  }
}


export const actionCommonUploads = (request, setIsShowLoading) => async (
  dispatch
) => {
  setIsShowLoading(true);
  try {
    const uploadResponse = await uploadRepository.uploadCommons({
      params: request,
    });
    dispatch(dispatchType.commonUploadSucsess(uploadResponse));
    setIsShowLoading(false);
  } catch (error) {
    dispatch(dispatchType.commonUploadError(error));
    setIsShowLoading(false);
  }
};
