import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  /*------------------------------------*\
    # Global
  \*------------------------------------*/
  .container-fluid {
    width: 100%;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-right: auto;
    margin-left: auto;
  }

  .container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 1200px) {
    .container {
      width: 1200px;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .container {
      max-width: 960px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .container {
      max-width: 720px;
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    .container {
      max-width: 540px;
    }
  }

    .kw-button-not-disabled{
      background-color: #2b476c !important;
      border-color: #2b476c !important;
      color: #ffffff !important;
      border-radius: 3px !important;
      width: 100%;
    }

    .kw-button-disabled {
      width: 100%;
      border-color: #2b476c !important;
      background: #C4C4C4 !important;
      border-radius: 3px !important;
    }

`;
