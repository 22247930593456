import React from "react";
import { Modal } from "antd";
import { Section } from "./style";
import successImg from "../../../assets/image/login/success.png";

export default function LoginSuccessModal({ visible, width, handleClose }) {
  return (
    <Modal visible={visible} width={width} onCancel={handleClose} footer={null}>
      <Section>
        <div className="modal-wrapper">
          <figure className="kw-modal-img">
            <img src={successImg} alt="login-success" />
          </figure>
          <h3 className="kw-modal-title">Signed in to Principal Dashboard Account</h3>
          <p className="kw-modal-body">
            To complete your loan application, please provide these information.
          </p>
          <p className="kw-modal-text">Redirecting...</p>
        </div>
      </Section>
    </Modal>
  );
}
