import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Modal,
  Select,
  Row,
  Col,
  Card,
  Spin,
  notification,
} from "antd";
import { Formik } from "formik";
import { schema } from "./schema";
import { postUploadLeads } from "../../stores/actions/leads";
import convertSchemaToInit from "../../library/convertSchemaToInit";
import "./style.css";
import { connect } from "react-redux";
import firebases from "../../firebase";
import "firebase/analytics";
import koininvoice from "../../repository/KoinInvoice/koininvoice";

const { Option } = Select;

function FormLeadsDashboard({
  isShowFormSupplierBuyerModal,
  handleChangeIsShowFormSupplierBuyerModal,
  postUploadLeads,
  account,
}) {
  const [initialValues, setInitialValues] = useState();
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [businessCategory, setBusinessCategory] = useState([])
  const analytics = firebases.analytics();

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    });
  };

  function doCreate() {
    const initValue = convertSchemaToInit(schema);
    setInitialValues({
      ...initValue,
      vendor_uuid: account.vendor && account.vendor.vendor_uuid,
      business_relationship: "supplier",
    });
  }

  useEffect(() => {
    doCreate();
    koininvoice.getCategoryLimitApplication({}).then((res) => {
      if (res?.status === 200) {
        setBusinessCategory(res?.data?.business_category)
      } else {
        setBusinessCategory([])
      }
    })// eslint-disable-next-line
  }, [account]);

  function handleChangeSubmit(params, resetForm) {
    postUploadLeads(
      params,
      handleChangeIsShowFormSupplierBuyerModal,
      setIsShowLoading,
      openNotificationWithIcon
    );
    analytics.logEvent("KI_vendor_leads_newForm_web", {
      lead_name: params.lead_name,
      pic_email: params.pic_email,
    });
    resetForm();
  }

  function handleChangeBusinessRelationship(value, option, setFieldValue) {
    setFieldValue("business_relationship", value);
  }

  function handleChangeBusinessCategory(value, option, setFieldValue) {
    setFieldValue("business_category", value);
  }

  function handleChangeBusinessType(value, option, setFieldValue) {
    setFieldValue("business_type", value);
  }

  return (
    <Modal
      visible={isShowFormSupplierBuyerModal}
      title="New Supplier / Borrower"
      footer={null}
      onCancel={() => handleChangeIsShowFormSupplierBuyerModal("cancel")}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          handleChangeSubmit(values, resetForm);
        }}
        validateOnChange={false}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <Card bordered={false}>
            <Form onFinish={handleSubmit}>
              <Form.Item
                label="Company Name"
                validateStatus={errors.lead_name && "error"}
                help={errors.lead_name}
              >
                <Input
                  placeholder="PT. Bridgestone Indonesia"
                  name="lead_name"
                  onChange={handleChange}
                  value={values.lead_name}
                />
              </Form.Item>
              <Form.Item
                label="Business Type"
                validateStatus={errors.business_type && "error"}
                help={errors.business_type}
              >
                <Select
                  defaultValue={values.business_type}
                  onChange={(value, option) =>
                    handleChangeBusinessType(
                      value,
                      option,
                      setFieldValue
                    )
                  }
                  name="business_type"
                >
                  <Option value="pt">PT</Option>
                  <Option value="cv">CV</Option>
                  <Option value="individual">Individual</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Company Address"
                validateStatus={errors.lead_address && "error"}
                help={errors.lead_address}
              >
                <Input
                  placeholder="Jl. Kalibiru Tim Dalam IV"
                  name="lead_address"
                  onChange={handleChange}
                  value={values.lead_address}
                />
              </Form.Item>
              <Form.Item
                label="PIC Name"
                validateStatus={errors.pic_name && "error"}
                help={errors.pic_name}
              >
                <Input
                  placeholder="Luthfia Hanum "
                  name="pic_name"
                  onChange={handleChange}
                  value={values.pic_name}
                />
              </Form.Item>
              <Form.Item
                label="Business Email"
                validateStatus={errors.pic_email && "error"}
                help={errors.pic_email}
              >
                <Input
                  placeholder="luthfia.hanum@bridgestone.co.id"
                  name="pic_email"
                  onChange={handleChange}
                  value={values.pic_email}
                />
              </Form.Item>
              <Form.Item
                label="Phone"
                validateStatus={errors.pic_phone_number && "error"}
                help={errors.pic_phone_number}
              >
                <Input
                  placeholder="+6281123252323"
                  name="pic_phone_number"
                  onChange={handleChange}
                  value={values.pic_phone_number}
                />
              </Form.Item>
              <Form.Item
                label="Business Relationship"
                validateStatus={errors.business_relationship && "error"}
                help={errors.business_relationship}
              >
                <Select
                  defaultValue={values.business_relationship}
                  onChange={(value, option) =>
                    handleChangeBusinessRelationship(
                      value,
                      option,
                      setFieldValue
                    )
                  }
                  name="business_relationship"
                >
                  <Option value="supplier">Supplier</Option>
                  <Option value="buyer">Buyer</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Business Category"
                validateStatus={errors.business_category && "error"}
                help={errors.business_category}
              >
                <Select
                  defaultValue={values.business_category}
                  onChange={(value, option) =>
                    handleChangeBusinessCategory(
                      value,
                      option,
                      setFieldValue
                    )
                  }
                  name="business_category"
                >
                  {businessCategory && businessCategory.map((list, index) => {
                    return <Option key={index} value={list.value}>{list.desc_id}</Option>
                  })}
                </Select>
              </Form.Item>
              <Row type="flex" justify="end" style={{ alignItems: "center" }}>
                <Col md={4}>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => handleChangeIsShowFormSupplierBuyerModal("cancel")}
                  >
                    CANCEL
                  </span>
                </Col>
                <Col md={4}>
                  <Spin spinning={isShowLoading}>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="kw-button-not-disabled"
                    >
                      SAVE
                    </Button>
                  </Spin>
                </Col>
              </Row>
            </Form>
          </Card>
        )}
      </Formik>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  postLeads: state.leads.postLeads,
});

export default connect(mapStateToProps, { postUploadLeads })(
  FormLeadsDashboard
);
