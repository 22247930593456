import styled from "styled-components";

export const Section = styled.div`
  .kw-dashboardLeads-heading {
    font-weight: bold;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: 0.41px;
    color: #2b486d;
  }
  td {
    line-height: 35px;
  }
  .ant-switch-checked {
    background: #38d07e;
  }
  .kw-dashboardLeads-button {
    border: 2px solid #2b486d;
    box-sizing: border-box;
    background: unset;
    color: #2B486D;
    border-radius: 3px;
  }
`;
