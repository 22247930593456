const KOINREVOLVER = "v1/koinrevolver/principal/";
const ON_GOING_LIST = `${KOINREVOLVER}${"loan/"}`;
const REJECT = `${ON_GOING_LIST}${"reject"}/`;
const CONFIRM = `${ON_GOING_LIST}${"confirm"}/`;
const ACCOUNT = `${KOINREVOLVER}account`
const UCL_GET = 'v1/loans/app/ucl/get?user_id='

export const PATH_LOAN = {
  ON_GOING_LIST,
  ACCOUNT,
  REJECT,
  CONFIRM,
  UCL_GET
};
