
import cookie from "react-cookies";
import { apiPostWithoutToken} from "../../services";
import { PATH_AUTHENTICATION } from "../../services/path/authentication";

async function checkAuthentication(props) {
  const actionChangeLoadingShow = props.actionChangeLoadingShow
    ? props.actionChangeLoadingShow
    : function() {};
  const actionChangeAlert = props.actionChangeAlert ? props.actionChangeAlert : function() {};
  let params = props.params;
  let response = "";
  actionChangeLoadingShow(true);
  try {
    response = await apiPostWithoutToken(
      PATH_AUTHENTICATION.AUTH_DOCUMENT,
      params
    );
    window.localStorage.setItem("token", response.data.data.access_token);
    cookie.save("token", response.data.data.access_token);
    actionChangeLoadingShow(false);
    actionChangeAlert(false);
  } catch (error) {
    actionChangeAlert(true);
    actionChangeLoadingShow(false);
    response = error.response;
  }
  return response && response.data;
}
const authentication = {
  checkAuthentication: checkAuthentication
};

export default authentication;
