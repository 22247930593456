const { apiGetWithToken, apiPostWithToken } = require("../../services");
const { PATH_LOAN } = require("../../services/path/loan");

async function getOnGoingList(props) {
  let response = "";
  let paramsId = props.paramsId;
  let loading = props.loading ? props.loading : function () {};
  loading(true);
  try {
    response = await apiGetWithToken(`${PATH_LOAN.ON_GOING_LIST}${paramsId}`);
    loading(false);
  } catch (error) {
    loading(false);
    response = error.response;
  }
  return response && response.data;
}

async function actionRejectList(props) {
  let response = "";
  let paramsId = props.paramsId;
  let loading = props.loading ? props.loading : function () {};
  loading(true);
  try {
    response = await apiPostWithToken(`${PATH_LOAN.REJECT}${paramsId}`);
    loading(false);
  } catch (error) {
    loading(false);
    response = error.response;
  }
  return response && response.data;
}

async function actionConfirmList(props) {
  let response = "";
  let paramsId = props.paramsId;
  let params = props.params
  let loading = props.loading ? props.loading : function () {};
  loading(true);
  try {
    response = await apiPostWithToken(`${PATH_LOAN.CONFIRM}${paramsId}`,params);
    loading(false);
  } catch (error) {
    loading(false);
    response = error.response;
  }
  return response && response.data;
}

async function actionGetAccount(props) {
  let response = "";
  let loading = props.loading ? props.loading : function () {};
  loading(true);
  try {
    response = await apiGetWithToken(`${PATH_LOAN.ACCOUNT}`);
    loading(false);
  } catch (error) {
    loading(false);
    response = error.response;
  }
  return response && response.data;
}

async function geUclList(props) {
  let response = "";
  let paramsId = props.paramsId;
  let loading = props.loading ? props.loading : function () {};
  loading(true);
  try {
    response = await apiGetWithToken(`${PATH_LOAN.UCL_GET}${paramsId}`);
    loading(false);
  } catch (error) {
    loading(false);
    response = error.response;
  }
  return response && response.data;
}

const loansReaderRepository = {
  getOnGoingList,
  actionGetAccount,
  actionRejectList,
  actionConfirmList,
  geUclList

};

export default loansReaderRepository;
