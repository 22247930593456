const schema = {
    last_applied_loan:undefined,
    lead_status:undefined,
    business_category:undefined,
    business_relationship: undefined,
    pic_name: undefined,
    lead_name: undefined,
    lead_code: undefined,
    limit: undefined,
    page: undefined
}

export default schema;