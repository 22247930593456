import React from "react";
import { Row, Col, Card, Input, Collapse, Button } from "antd";
import { Section } from "./style";

const { Search } = Input;

const { Panel } = Collapse;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

export default function DashboardHelps(props) {
  return (
    <Section>
      <div className="container-fluid kw-dashboardHelps">
        <h3 className="kw-dashboardHelps__heading-text">Help</h3>
        <Row style={{ marginLeft: 30 }}>
          <Col md={15}>
            <Card>
              <strong>
                We would love to assist you to use this dashboard effectively.
              </strong>
              <Search
                placeholder="Search Issue"
                onSearch={(value) => console.log(value)}
                style={{ width: 200, marginTop: 15 }}
              />
              <h4>Getting Started</h4>
              <Collapse accordion>
                <Panel header="How do I activate my account?" key="1">
                  <p>{text}</p>
                </Panel>
                <Panel header="How do I register my company account? " key="2">
                  <p>{text}</p>
                </Panel>
                <Panel
                  header="How do I add a lead (supplier/buyer) to my KoinWorks account?"
                  key="3"
                >
                  <p>{text}</p>
                </Panel>
                <Panel
                  header="How do I add multiple leads at the same time to my KoinWorks account? "
                  key="4"
                >
                  <p>{text}</p>
                </Panel>
              </Collapse>
              <h4>Managing Leads</h4>
              <Collapse accordion>
                <Panel header="How do I view current outstanding?" key="1">
                  <p>{text}</p>
                </Panel>
                <Panel header="How do I edit a lead?" key="2">
                  <p>{text}</p>
                </Panel>
                <Panel header="How do I remove a lead?" key="3">
                  <p>{text}</p>
                </Panel>
                <Panel header="How to re-add removed employee? " key="4">
                  <p>{text}</p>
                </Panel>
              </Collapse>
              <h4>Cannot find what you are looking for?</h4>
              <p className="kw-dashboardHelps__callFindUs">
                You have spent all your time scrolling FAQ but did not find what
                you are looking for? Call us or find us at:
              </p>
              <p className="kw-dashboardHelps__addressKw">
                PT. Lunaria Annua Teknologi Jl. HR Rasuna Said. Blok X5 No 13
                Kuningan, Setia Budi Jakarta Selatan 12950 Indonesia
              </p>
              <Button type="primary" style={{ marginRight: 10 }} size="medium">
                CALL CUSTOMER SUPPORT
              </Button>
              <Button type="primary" size="medium">
                SEND EMAIL
              </Button>
            </Card>
          </Col>
        </Row>
      </div>
    </Section>
  );
}
