import React from "react";
import { Button } from "reactstrap";
import strings from "../../../localization/localization";

class NotFound extends React.Component {
  render() {
    return (
      <div className="error-box">
        <div className="error-box__head">
          <h1>404</h1>
        </div>
        <div className="error-box__content">
          <h2>{strings.error.errorTitle}</h2>
          <p>{strings.error.errorDesc}</p>
          <Button color="primary" href="/">
            {strings.error.errorButton}
          </Button>
        </div>
      </div>
    );
  }
}

export default NotFound;
