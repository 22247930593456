import styled from "styled-components";

export const Section = styled.div`
  .kw-dashboard-successFee {
    margin-top: 50px;
    margin-left: 50px;
    margin-bottom: 50px; 
    &__total {
      position: absolute;
      right: 130px;
      bottom: 50px;
      color: #1890ff;
      font-weight: bold;
    }
    h3 {
      font-weight: bold;
      font-size: 30px;
      line-height: 41px;
      align-items: center;
      letter-spacing: 0.41px;
      color: #2b486d;
    }
  }
`;
