import React, { useReducer, useContext, Fragment } from "react";
import authentication from "../../repository/Authentication/authentication";
import cookie from "react-cookies";
import leadsRepository from "../../repository/Leads/leads";
import loansReaderRepository from "../../repository/Loans/loans";
import firebases from "../../firebase";
import "firebase/analytics";

const CreateRootContext = React.createContext();

const RootContext = (props) => {
  const token = cookie.load("token");
  const initialState = {
    isAuthenticated: token ? true : false,
    authDocument: "",
    isErrorAuthentication: true,
    principalAccount: {},
    principalAccountError: "",
    onGoingList: {},
    onGoingListError: "",
    isShowAlertOnGoing: false,
  };
  const analytics = firebases.analytics();

  const reducer = (state, action) => {
    switch (action.type) {
      case "auth-document":
        return {
          ...state,
          authDocument: action.payload.data,
          isErrorAuthentication: false,
          isAuthenticated: true,
        };
      case "auth-document-error":
        return {
          ...state,
          authenticationError:
            action.payload &&
            action.payload.message &&
            `${action.payload.message.en} / ${action.payload.message.id}`,
          isErrorAuthentication: true,
          isAuthenticated: false,
        };
      case "principal-account-sucess":
        return {
          ...state,
          principalAccount: action.payload,
        };
      case "principal-account-error":
        return {
          ...state,
          principalAccountError: action.payload,
        };
      case "ongoing-list-sucess":
        return {
          ...state,
          onGoingList: action.payload,
          isShowAlertOnGoing: false,
        };
      case "ongoing-list-error":
        return {
          ...state,
          onGoingListError: `${action.payload.message.en} / ${action.payload.message.id}`
            ? `${action.payload.message.en} / ${action.payload.message.id}`
            : "An unexpected error occured. Please try again later / Terjadi kesalahan tak terduga Silahkan coba lagi nanti",
          isShowAlertOnGoing: true,
        };
      case "logout":
        return {
          ...initialState,
          isAuthenticated: false,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  async function principalAccountChange(setIsShowLoading) {
    const accountChange = await leadsRepository.getPrincipalAccount({
      loading: setIsShowLoading,
    });
    if (accountChange && accountChange.status === 200) {
      dispatch({
        type: "principal-account-sucess",
        payload: accountChange,
      });
      window.localStorage.setItem(
        "account-change",
        JSON.stringify(accountChange)
      );
      if (
        accountChange.data.vendor.vendor_id === null ||
        accountChange.data.vendor.vendor_id <= 0
      ) {
        cookie.remove("token");
      }
    } else {
      dispatch({
        type: "principal-account-error",
        payload: accountChange,
      });
    }
  }

  async function onGoingListChange(paramsId, setIsShowLoadingOnChange) {
    const goingList = await loansReaderRepository.getOnGoingList({
      paramsId: paramsId,
      loading: setIsShowLoadingOnChange,
    });
    // const tempReversedAuthDocument = [];
    if (goingList?.status === 200) {
      //   if (goingList && goingList.data) {
      //     if (goingList?.data !== null) {
      //       for (let i = goingList && goingList.data.length - 1; i >= 0; i--) {
      //         tempReversedAuthDocument.push(goingList.data[i]);
      //       }
      //     }
      //   }
      dispatch({
        type: "ongoing-list-sucess",
        payload: goingList.data,
      });
    } else {
      dispatch({
        type: "ongoing-list-error",
        payload: goingList,
      });
    }
  }

  const documentAuthentication = async (
    body,
    actionChangeAlert,
    actionChangeLoadingShow
  ) => {
    const responseAuthentication = await authentication.checkAuthentication({
      params: body,
      actionChangeAlert: actionChangeAlert,
      actionChangeLoadingShow: actionChangeLoadingShow,
    });
    if (responseAuthentication && responseAuthentication.status === 200) {
      const indexesToLocalstorage = JSON.stringify(body);
      localStorage.setItem("authData", indexesToLocalstorage);
      dispatch({
        type: "auth-document",
        payload: responseAuthentication,
      });
    } else {
      dispatch({
        type: "auth-document-error",
        payload: responseAuthentication,
      });
    }
  };

  const logout = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("authData");
    window.localStorage.removeItem("account-change");
    analytics.logEvent("KI_vendor_logut_web");
    cookie.remove("token");
    dispatch({
      type: "logout",
    });
  };

  return (
    <Fragment>
      <CreateRootContext.Provider
        value={{
          ...state,
          checkAuthentication: (
            body,
            actionChangeAlert,
            actionChangeLoadingShow,
            analytics
          ) => {
            documentAuthentication(
              body,
              actionChangeAlert,
              actionChangeLoadingShow,
              analytics
            );
          },
          actionPrincipalAccount: (setIsShowloading) => {
            principalAccountChange(setIsShowloading);
          },
          actionOnGoingList: (paramsId, setIsShowLoadingOnChange) => {
            onGoingListChange(paramsId, setIsShowLoadingOnChange);
          },
          handleLogout: () => {
            logout();
          },
          match: props.match,
          history: props.history,
        }}
      >
        {props.children}
      </CreateRootContext.Provider>
    </Fragment>
  );
};
export const useRootContext = () => useContext(CreateRootContext);
export default RootContext;
