import React, { useState, useRef, useEffect } from "react";
import { Section } from "./style";
import { Button, Typography, Input, Card, Spin, Form } from "antd";
import LinkForgotPassword from "../../library/LinkForgotPassword";
import { useRootContext } from "../../hoc/RootContext";
import LoginSuccessModal from "../../components/Modal/LoginSuccessModal";
import { schema } from "./schema";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import firebases from "../../firebase";
import "firebase/analytics";

const { Text } = Typography;

function Login(props) {
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isShowLoading, setIshowLoading] = useState(false);
  const [isShowAlertCorporate, setIsShowAlertCorporate] = useState(false);
  const { t } = useTranslation();
  const {
    authDocument,
    checkAuthentication,
    authenticationError,
    actionPrincipalAccount,
    principalAccount,
    history,
  } = useRootContext();
  const mounted = useRef();
  useEffect(() => {
    if (authDocument) {
      actionPrincipalAccount(setIshowLoading);
    } // eslint-disable-next-line
  }, [authDocument]);
console.log(authDocument &&
  principalAccount &&
  principalAccount.data &&
  principalAccount.data);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (
        (authDocument &&
          principalAccount &&
          principalAccount.data &&
          principalAccount.data.vendor.vendor_id > 0) ||
        (authDocument &&
          principalAccount &&
          principalAccount.data &&
          principalAccount.data.vendor.vendor_id !== null)
      ) {
        setVisible(true)
        setTimeout(() => {
          history.push("/dashboard/leads");
         setVisible(false)
        }, 1500);
        setIsShowAlertCorporate(false);
      } else {
        if (
          (authDocument &&
            principalAccount &&
            principalAccount.data &&
            principalAccount.data.vendor.vendor_id <= 0) ||
          (authDocument &&
            principalAccount &&
            principalAccount.data &&
            principalAccount.data.vendor.vendor_id === null)
        ) {
          setIsShowAlertCorporate(true);
          setVisible(false);
        }
      }
    } // eslint-disable-next-line
  }, [authDocument, principalAccount]);

  const analytics = firebases.analytics();

  function actionChangeLoadingShow(value) {
    setIshowLoading(value);
  }

  function onClickShowHideAllert(value) {
    if (value) {
      setIsShowAlert(false);
      setIsShowAlertCorporate(false);
    }
  }

  function actionChangeAlert(value) {
    setIsShowAlert(value);
  }

  function handleOnSubmit(values) {
    checkAuthentication(values, actionChangeAlert, actionChangeLoadingShow);
    analytics.logEvent("KI_signin_businessacc_dashboardVendor_web", {
      business_email: values.email,
    });
  }

  function handleChangeClose() {
    setVisible(!visible);
  }

  return (
    <React.Fragment>
      {" "}
      <LoginSuccessModal
        width={300}
        visible={visible}
        handleClose={handleChangeClose}
      />
      <Section style={{ paddingTop: 110 }}>
        <img
          src={require("../../assets/image/background-red-circle.png")}
          className="kw-login-imageTop"
          alt=""
        />
        <Card style={{ zIndex: 5 }}>
          <div className="kw-login">
            <div className="kw-login__main">
              <h3 className="kw-login__main-label">Login to Your Dashboard</h3>
              <span className="kw-login__second-label">
                Principal Dashboard
              </span>
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={schema}
                onSubmit={(values) => {
                  handleOnSubmit(values);
                }}
                validateOnChange={false}
              >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Form.Item
                      validateStatus={errors.email && "error"}
                      help={
                        errors.email && t("validation:email." + errors.email)
                      }
                      style={{ marginBottom: 20 }}
                    >
                      <Input
                        onClick={onClickShowHideAllert}
                        placeholder="EMAIL ADDRESS"
                        data-testid="email"
                        name="email"
                        size="large"
                        onChange={handleChange}
                        value={values.email}
                      />
                    </Form.Item>
                    <Form.Item
                      validateStatus={errors.password && "error"}
                      help={
                        errors.password &&
                        t("validation:password." + errors.password)
                      }
                    >
                      <Input.Password
                        placeholder="input password"
                        data-testid="password"
                        name="password"
                        type="password"
                        size="large"
                        value={values.password}
                        onChange={handleChange}
                      />
                    </Form.Item>
                    <LinkForgotPassword />
                    {isShowAlert && (
                      <div style={{ margin: 15 }}>
                        <Text type="danger">{authenticationError}</Text>
                      </div>
                    )}
                    {isShowAlertCorporate && (
                      <div style={{ margin: 15 }}>
                        <Text type="danger">
                          {"Maaf akun ada bukan terdaftar Corporate"}
                        </Text>
                      </div>
                    )}
                    <Spin spinning={isShowLoading}>
                      <Button
                        data-testid="btnSubmit"
                        htmlType="submit"
                        className={"kw-button-not-disabled"}
                        size="large"
                      >
                        SIGN IN
                      </Button>
                    </Spin>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
        <img
          src={require("../../assets/image/combinane-shape.png")}
          className="kw-image-banneShape"
          alt=""
        />
      </Section>
    </React.Fragment>
  );
}

export default Login;
