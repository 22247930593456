import TYPE from "../actions/type";

const initialState = {
  listLeads: [],
  postLeads: {},
  uploadLeads: {},
  province:{},
  provinceError:"",
  city:{},
  cityError:""
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPE.GET_LEADS_SUCSESS:
      return {
        ...state,
        listLeads: action.payload,
      };
    case TYPE.GET_LEADS_SUCSESS_ERROR:
      return {
        ...state,
        listLeads: action.payload,
      };
    case TYPE.POST_LEADS_SUCSESS:
      return {
        ...state,
        postLeads: action.payload,
      };
    case TYPE.POST_LEADS_ERROR:
      return {
        ...state,
        postLeads: action.payload,
      };
    case TYPE.POST_UPLOAD_LEADS_SUCSESS:
      return {
        ...state,
        uploadLeads: action.payload,
      };
    case TYPE.POST_UPLOAD_LEADS_ERROR:
      return {
        ...state,
        uploadLeads: action.payload,
      };
    case TYPE.BUTTON_SUBMIT_UPLOAD_FORM:
      return {
        ...state,
        uploadLeads: {},
        postLeads: {},
      };
    case TYPE.PROVINCE_SUCSESS:
      return {
        ...state,
        province: action.payload,
      };
    case TYPE.PROVINCE_ERROR:
      return {
        ...state,
        provinceError: action.payload,
      };
    case TYPE.CITY_SUCSESS:
      return {
        ...state,
        city: action.payload,
      };
    case TYPE.CITY_ERROR:
      return {
        ...state,
        cityError: action.payload,
      };
    default:
      return state;
  }
};
