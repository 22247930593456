import React, { useEffect, useState } from "react";
import { Section } from "./style";
import { Table, Card } from "antd";
import currencyRupiah from "../../library/currency";
import feeRepository from "../../repository/Fee/fee";

export default function DashboardSuccessFee(props) {
  const accountChange =
    JSON.parse(window.localStorage.getItem("account-change")) !== null &&
    JSON.parse(window.localStorage.getItem("account-change")).data;
  const {
    vendor: { vendor_name },
  } = accountChange;
  const [successFee, setSuccessFee] = useState([]);
  const [totalSuccsessFee,setTotalSuccessFee] = useState(0)
  const [isShowLoading, setIsShowLoading] = useState(false);

  useEffect(() => {
    feeRepository.getFeeList({ loading: setIsShowLoading }).then((res) => {
      if (res?.status === 200 && res?.data?.data?.length > 0) {
        setSuccessFee(convertToSchema(res?.data?.data));
        setTotalSuccessFee(res?.data?.total_commission_fee)
      } else {
        setSuccessFee([]);
      }
    });
  }, []);

  function schemaList(
    {
      lead_name,
      user_code,
      loan_id,
      loan_amount,
      admin_fee,
      insurance_fee,
      origination_fee,
    },
    index
  ) {
    return {
      index: index,
      lead_name,
      user_code,
      loan_id,
      loan_amount,
      admin_fee,
      insurance_fee,
      origination_fee,
      comission_fee: admin_fee + insurance_fee + origination_fee,
    };
  }

  function convertToSchema(response) {
    return response.map((data, index) => schemaList(data, index));
  }

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      no: "number",
      render: (text, record) => <span>{successFee.indexOf(record) + 1}</span>,
    },
    {
      title: "Borrower Name",
      dataIndex: "lead_name",
      key: "lead_name",
    },
    {
      title: "Borrower ID",
      dataIndex: "user_code",
      key: "user_code",
    },
    {
      title: "Loan ID",
      dataIndex: "loan_id",
      key: "loan_id",
    },
    {
      title: "Loan Amount",
      dataIndex: "loan_amount",
      key: "loan_amount",
      render: (text) => <span>{currencyRupiah(text)}</span>,
    },
    {
      title: "Admin Fee",
      dataIndex: "admin_fee",
      key: "admin_fee",
      render: (text) => <span>{currencyRupiah(text)}</span>,
    },
    {
      title: "Insurance Fee",
      dataIndex: "insurance_fee",
      key: "insurance_fee",
      render: (text) => <span>{currencyRupiah(text)}</span>,
    },
    {
      title: "Origination Fee",
      dataIndex: "origination_fee",
      key: "origination_fee",
      render: (text) => <span>{currencyRupiah(text)}</span>,
    },
    {
      title: "Commission Fee ",
      dataIndex: "comission_fee",
      key: "comission_fee",
      render: (text) => <span>{currencyRupiah(text)}</span>,
    },
  ];

  return (
    <Section>
      <div className="kw-dashboard-successFee">
        <h3>
          Principal :{" "}
          {vendor_name.charAt(0).toUpperCase() + vendor_name.slice(1)}
        </h3>
        <Card>
          <Table
            columns={columns}
            rowKey={(record) => record.index}
            dataSource={successFee}
            loading={isShowLoading}
            pagination={{ position: ["none", "bottomLeft"] }}
          />
          <strong className="kw-dashboard-successFee__total">
            Total : {currencyRupiah(totalSuccsessFee)}
          </strong>
        </Card>
      </div>
    </Section>
  );
}
