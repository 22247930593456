import React, { useEffect } from "react";
import { useRootContext } from "../../hoc/RootContext";

export default function AppLayout({ needAuthenticated, children }) {
  const {
    isAuthenticated,
    history,
  } = useRootContext();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (needAuthenticated && !isAuthenticated) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (needAuthenticated && !isAuthenticated) {
    return null;
  } else {
      return <React.Fragment>{children}</React.Fragment>;
  }
}
