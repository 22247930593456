import React,{useEffect} from "react";
import ReactApexChart from "react-apexcharts";
import { Card } from "antd";
import CardDashboardPrincipal from "../../components/CardDashboardPrincipal";
import { Section } from "./style";
import { useRootContext } from "../../hoc/RootContext";

export default function DashboardPrincipal(props) {
  const accountChange =
    JSON.parse(window.localStorage.getItem("account-change")) !== null &&
    JSON.parse(window.localStorage.getItem("account-change")).data;
  const {
    vendor: { vendor_name,vendor_code },
  } = accountChange;
  const { isAuthenticated, history } = useRootContext();

  const series = [
    {
      name: "Desktops",
      data: [10, 41, 35, 51, 49, 62, 69, 91, 850, 1000],
    },
    {
      name: "Desktops3",
      data: [17, 45, 55, 552, 434, 625, 649, 750, 850, 1000],
    },
  ];
  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    // title: {
    //   align: "left",
    //   style: {
    //     fontSize: "22px",
    //     fontWeight: "bold",
    //     fontFamily: undefined,
    //     color: "#2B486D",
    //   },
    // },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: ["#5FE9C0", "#DDBB94"],
    xaxis: {
      categories: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    },
  };

  useEffect(() => {
    isAuthenticated === false && history.push("/"); // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <div className="container" style={{ marginBottom: 50 }}>
      <Section>
        <Card style={{ marginTop: 50, marginBottom: 50 }}>
          <h3 style={{ color: "#2B486D" }}>
            Principal :{" "}
            {vendor_name.charAt(0).toUpperCase() + vendor_name.slice(1)}
            <span style={{ color: "black", marginLeft: 10 }}>
              Code :{" "}
              {vendor_code}
            </span>
          </h3>
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height={350}
            />
        </Card>
        <CardDashboardPrincipal />
      </Section>
    </div>
  );
}
