import React from "react";
import { Modal } from "antd";
import "./style.css";

export default function InvoiceOnGoingDashboard({
  isShowModalViewInvoice,
  handleChangeShowHideModalViewInvoice,
}) {
  return (
    <Modal
      title=""
      visible={isShowModalViewInvoice}
      footer={null}
      onCancel={handleChangeShowHideModalViewInvoice}
    >
      <div
        style={{
          marginTop: 22,
        }}
      >
        <img
          src={require("../../assets/image/image-invoice-onGoing.png")}
          alt=""
          style={{ width: 700, margin: "0 auto", display: "block" }}
        />
      </div>
    </Modal>
  );
}
