import PATH_URL from "./path";
import Login from "../pages/Login";
import LoginLayout from "../layouts/LoginLayout";
import DashboardPrincipal from "../pages/DashboardPrincipal";
import DashboardLayout from "../layouts/DashboardLayout";
import ErrorPage from "../pages/NotFound/NotFound";
import MainLayout from "../layouts/MainLayout";
import DashboardLeads from "../pages/DashboardLeads";
import DashboardSuccessFee from "../pages/DashboardSuccessFee";
import DashboardOnGoing from "../pages/DashboardOnGoing";
import DashboardHelps from "../pages/DashboardHelps";
import DashboardSettings from "../pages/DashboardSettings";

const routes = [
  {
    path: PATH_URL.LOGIN,
    component: Login,
    layout: LoginLayout,
    needAuthenticated: false,
  },
  {
    path: PATH_URL.DASHBOARD_PRINCIPAL,
    component: DashboardPrincipal,
    layout: DashboardLayout,
    needAuthenticated: true,
  },
  {
    path: PATH_URL.DASHBOARD_LEADS,
    component: DashboardLeads,
    layout: DashboardLayout,
    needAuthenticated: true,
  },
  {
    path: PATH_URL.DASHBOARD_SUCCES_FEE,
    component: DashboardSuccessFee,
    layout: DashboardLayout,
    needAuthenticated: true,
  },
  {
    path: PATH_URL.DASHBOARD_ON_GOING,
    component: DashboardOnGoing,
    layout: DashboardLayout,
    needAuthenticated: true,
  },
  {
    path: PATH_URL.DASHBOARD_HELP,
    component: DashboardHelps,
    layout: DashboardLayout,
    needAuthenticated: true,
  },
  {
    path: PATH_URL.DASHBOARD_SETTINGS,
    component: DashboardSettings,
    layout: DashboardLayout,
    needAuthenticated: true,
  },
  {
    path: PATH_URL.NOT_FOUND,
    component: ErrorPage,
    layout: MainLayout,
    needAuthenticated: false,
  },
];

export default routes;
