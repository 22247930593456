import { PATH_UPLOAD } from "../../services/path/upload";
import { apiPostWithoutToken } from "../../services";

async function uploadCommons(props) {
  let response = "";
  let params = props.params;
  try {
    response = await apiPostWithoutToken(PATH_UPLOAD.COMMON_UPLOAD, params);
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

const uploadRepository = {
  uploadCommons,
};

export default uploadRepository;