import React from "react";
import { Modal, Button } from "antd";
import "./style.css"

export default function SuccessFormLeadsDashboard({
  isShowSuccesFormSupplierBuyerModal,
  handleChangeIsShowSucesFormSupplierBuyerModal,
}) {
  return (
    <Modal
      visible={isShowSuccesFormSupplierBuyerModal}
      closable={false}
      footer={null}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <div>
          <img
            src={require("../../assets/image/icon-success-save-leads.png")}
            style={{ width: 233 }}
            alt=""
          />
        </div>
        <h5 style={{ color: "#2B486D", fontSize: 24 }}>
          Invite new suppliers/ borrowers
        </h5>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p style={{ width: 250 }}>
            Congrats! An invitation has been sent to
            luthfia.hanum@bridgestone.co.id
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p>
            Start earning commision by making the company apply for a loan
            through KoinWorks as soon as possible!
          </p>
        </div>
        <div><Button type="primary" onClick={handleChangeIsShowSucesFormSupplierBuyerModal} className="button-success-leads-dashboard">DONE</Button></div>
      </div>
    </Modal>
  );
}
