import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "./style";

export default function ButtonLanguage() {
  const { i18n } = useTranslation();
  const [languageSelected, setLanguageSelected] = useState(i18n.language);
  const language = [
    {
      id: 1,
      language: "id"
    },
    {
      id: 2,
      language: "en"
    }
  ];

  const changeLanguage = language => {
    setLanguageSelected(language);
    i18n.changeLanguage(language);
  };

  const languages = language.map((lang, index) => {
    return (
      <span
        key={index}
        className={`kw-docs-button-language ${
          languageSelected === lang.language ? "active" : "notActive"
        }`}
        onClick={() => changeLanguage(lang.language)}
      >
        {lang.language.toUpperCase()}
      </span>
    );
  });

  return <Section>{languages}</Section>;
}