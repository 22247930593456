import React from "react";
import { Section } from "./style";
import { DownOutlined } from "@ant-design/icons";
import { Row, Col, Dropdown, Menu } from "antd";
import ButtonLanguage from "../ButtonLanguage";
import { Link } from "react-router-dom";
import { useRootContext } from "../../hoc/RootContext";

export default function Header(props) {
  const { handleLogout } = useRootContext();
  const accountChange =
    JSON.parse(window.localStorage.getItem("account-change")) !== null &&
    JSON.parse(window.localStorage.getItem("account-change")).data;
  const {
    vendor: { vendor_name },
  } = accountChange;

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/" onClick={() => handleLogout()}>
          Logout
        </Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <Section>
      <div className="kw-header-wrapperDashboardLayout">
        <Row>
          <Col md={24}>
            <div style={{ position: "absolute", right: 0, top: 20 }}>
              <div style={{ display: "flex" }}>
                <Dropdown overlayStyle={{}} overlay={menu}>
                  <span
                    style={{
                      marginRight: 55,
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    {vendor_name.charAt(0).toUpperCase() + vendor_name.slice(1)}
                    <DownOutlined
                      style={{ cursor: "pointer", marginLeft: 10 }}
                    />
                  </span>
                </Dropdown>
                <ButtonLanguage />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Section>
  );
}
