import styled from "styled-components";

export const Section = styled.div`
  .kw-loginLayout {
    background: #e5e5e5;
    &__main {
      position: relative;
      max-width: 400px;
      width: 100%;
      box-sizing: border-box;
      min-height: calc(100vh - 60px);
      margin: 0px auto;
    }
  }
`;
