import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings({
  en: {
    error: {
      errorTitle: "Page Not Found",
      errorDesc:
        "We're sorry, the page you requested was not found. Likely the page has been deleted, or You write down the URL.",
      errorButton: "Back to Home",
      errorFetch: "Unable to fetch data. Please try again in a few minutes.",
      serverError: "Internal Server Error",
      serverDesc: "Please try again in a few minutes.",
      failedFetch: "Failed to fetch",
    },
  },
  id: {
    error: {
      errorTitle: "Halaman Tidak Ditemukan",
      errorDesc:
        "Mohon maaf, halaman yang Anda cari tidak ditemukan. Kemungkinan halaman telah dihapus, atau Anda salah menuliskan URL.",
      errorButton: "Kembali ke Beranda",
      errorFetch:
        "Tidak dapat mengambil data. Silahkan coba lagi dalam beberapa menit.",
      serverError: "Kesalahan Server Internal",
      serverDesc: "Silahkan coba lagi dalam beberapa menit.",
      failedFetch: "Gagal Memuat",
    },
  },
});

export default strings;
