import axios from "axios";
import cookie from "react-cookies";

function getToken() {
  return cookie.load("token");
  // return window.localStorage.getItem("token");
}

const REACT_APP_API = process.env.REACT_APP_API_MAIN_SERVICE

export const serviceWithToken = (token = getToken()) =>
  axios.create({
    baseURL: REACT_APP_API,
    timeout: 60 * 4 * 1000,
    headers: {
      Authorization: "Bearer " + token
    }
  });

export const serviceWithoutToken = () =>
  axios.create({
    baseURL: REACT_APP_API,
    timeout: 60 * 4 * 1000,
    headers: {
      "Content-Type": `application/json`
    }
  });

